'use strict';
angular.module('cpqFactoryApp', [
  'ngResource',
  'ngRoute',
  'ngSanitize',
  'ui.bootstrap',
  'indexedDB',
  'utils',
  'angular-md5'
]).config([
  '$httpProvider',
  '$routeProvider',
  'DataProvider',
  'ProbeProvider',
  function ($httpProvider, $routeProvider, DataProvider, ProbeProvider) {
    $httpProvider.interceptors.push('AuthInterceptor');
    if (ProbeProvider.getInternetExplorerVersion() > 10) {
      DataProvider.useCompoundIndexes = false;
    }
    DataProvider.setDatabase('cpq', 2, function (e) {
      var db = e.target.result;
      if (e.oldVersion === 0) {
        // creates catalogs store
        var catalogs = db.createObjectStore('catalogs', {
            keyPath: DataProvider.formatIndex([
              'userId',
              'id'
            ]),
            autoIncrement: false
          });
        catalogs.createIndex('by_user', DataProvider.formatIndex('userId'), { unique: false });
        // creates categories store
        var categories = db.createObjectStore('categories', {
            keyPath: DataProvider.formatIndex([
              'userId',
              'id'
            ]),
            autoIncrement: false
          });
        categories.createIndex('by_user', DataProvider.formatIndex('userId'), { unique: false });
        // creates products store
        var products = db.createObjectStore('products', {
            keyPath: DataProvider.formatIndex([
              'userId',
              'catalogId',
              'id'
            ]),
            autoIncrement: false
          });
        products.createIndex('by_user', DataProvider.formatIndex('userId'), { unique: false });
        products.createIndex('by_user_and_catalog', DataProvider.formatIndex([
          'userId',
          'catalogId'
        ]), { unique: false });
        products.createIndex('by_user_catalog_and_category', DataProvider.formatIndex([
          'userId',
          'catalogId',
          'categoryId'
        ]), { unique: false });
        /* falls through */
        // creates quotes store
        var quotes = db.createObjectStore('quotes', {
            keyPath: DataProvider.formatIndex([
              'userId',
              'guid',
              'quoteVersion'
            ]),
            autoIncrement: false
          });
        quotes.createIndex('by_user', DataProvider.formatIndex('userId'), { unique: false });
        quotes.createIndex('by_user_and_guid', DataProvider.formatIndex([
          'userId',
          'guid'
        ]), { unique: false });
        // creates plm status store
        var plmstates = db.createObjectStore('plmstates', {
            keyPath: DataProvider.formatIndex([
              'userId',
              'id'
            ]),
            autoIncrement: false
          });
        plmstates.createIndex('by_user', DataProvider.formatIndex('userId'), { unique: false });
      }
      if (e.oldVersion <= 1) {
      }
    });
    $routeProvider.when('/login', {
      templateUrl: 'views/login.html',
      controller: 'LoginCtrl',
      protect: false,
      master: false
    }).when('/products', {
      templateUrl: 'views/products.html',
      controller: 'ProductsCtrl',
      protect: true,
      master: true
    }).when('/products/:categoryId', {
      templateUrl: 'views/category.html',
      controller: 'ProductsCategoryCtrl',
      protect: true,
      master: true
    }).when('/products/:categoryId/configure/:itemId', {
      templateUrl: 'views/products/configure.html',
      controller: 'ProductsConfigureCtrl',
      protect: true,
      master: true
    }).when('/products/:categoryId/configure/:itemId/serialNumbers/:serials', {
      templateUrl: 'views/products/configure.html',
      controller: 'ProductsConfigureCtrl',
      protect: true,
      master: true
    }).when('/products/:categoryId/options/:itemId', {
      templateUrl: 'views/products/options.html',
      controller: 'ProductsConfigureCtrl',
      protect: true,
      master: true
    }).when('/quote', {
      templateUrl: 'views/quote.html',
      controller: 'QuoteCtrl',
      protect: true,
      master: true
    }).when('/quote/preview', {
      templateUrl: 'views/preview.html',
      controller: 'QuoteCtrl',
      protect: false,
      master: false
    }).when('/quote/configure/:itemId', {
      templateUrl: 'views/quote/configure.html',
      controller: 'QuoteConfigureCtrl',
      protect: true,
      master: true
    }).when('/quote/options/:itemId', {
      templateUrl: 'views/quote/options.html',
      controller: 'QuoteConfigureCtrl',
      protect: true,
      master: true
    }).when('/unsupported', {
      templateUrl: 'views/unsupported.html',
      controller: 'UnsupportedCtrl',
      protect: false,
      master: false
    }).when('/quote/archived', {
      templateUrl: 'views/archived.html',
      controller: 'ArchivedCtrl',
      protect: true,
      master: true
    }).when('/quote/crm/:oppId', {
      templateUrl: 'views/quote.html',
      controller: 'QuoteCtrl',
      protect: true,
      master: true
    }).when('/quote/crm/intref/:guid/:version', {
      templateUrl: 'views/quote.html',
      controller: 'QuoteCtrl',
      protect: true,
      master: true
    }).otherwise({ redirectTo: '/login' });
  }
]).run([
  '$rootScope',
  '$location',
  '$q',
  '$route',
  'User',
  'Status',
  'Maintenance',
  'Quote',
  'Quotes',
  'Probe',
  'Api',
  'Storage',
  function ($rootScope, $location, $q, $route, User, Status, Maintenance, Quote, Quotes, Probe, Api, Storage) {
    $rootScope.appName = 'cpq';
    // replaced on build
    $rootScope.appVersion = '1.1';
    // replaced on build
    $rootScope.restart = function () {
      window.location.reload();
    };
    $rootScope.lockScreen = function (message) {
      $rootScope.progress = message;
    };
    $rootScope.unlockScreen = function () {
      $rootScope.progress = null;
    };
    /**
         * Toggle master visibility and protects routes that need authorisation.
         */
    $rootScope.$on('$routeChangeStart', function (event, next) {
      $rootScope.master = next.master;
      if (!User.isLoggedIn() && next.protect) {
        if ($location.url() != '/login' && $location.url() != '/') {
          Storage.remove('returnUrl');
          Storage.set('returnUrl', $location.url());
        }
        ;
        $location.path('/login');
      }
      //redirect to login if the status is online
      //but the session in backend is not valid anymore
      if (User.isLoggedIn() && (Status.online == undefined || Status.online == true)) {
        Api.auth.pollSession(User.getLogin()).error(function (response) {
          User.lock();
          $rootScope.restart();
        });
      }
    });
    /**
         * Removes user from persistent storage.
         */
    $rootScope.$on('user:destroy', function () {
      User.destroy();
      $rootScope.restart();
    });
    /**
         * Removes all offline data.
         */
    $rootScope.$on('data:destroy', function () {
      $q.all([
        Maintenance.deleteStorage(),
        Maintenance.deleteData()
      ]).then($rootScope.restart);
    });
    $rootScope.$on('QUOTE_CHANGE', function () {
      if (User.isLoggedIn() && Status.online) {
        Api.auth.pollSession(User.getLogin()).success(function (response) {
          Maintenance.syncQuotes();
        });
      }
    });
    /**
         * Detects required features and returns false if one of them is not supported.
         */
    if (!Probe.detect()) {
      $location.path('/unsupported');
      return;
    }
    /**
         * Checks server status and updates data.
         */
    Status.poll().then(function () {
      if (User.isLoggedIn() && Status.online) {
        Maintenance.prefetchTemplates();
        if (User.isLoggedIn() && $route.current.protect) {
          Api.auth.pollSession(User.getLogin()).success(function (response) {
            $rootScope.lockScreen('Updating catalogs, please wait...');
            Maintenance.updateData().then(function (products) {
              if (products && products.length > 0) {
                $route.reload();
              }
              $rootScope.unlockScreen();
            });
          });
        }
      }
    });
  }
]);
'use strict';
angular.module('cpqFactoryApp').factory('Api', [
  '$resource',
  '$http',
  '$q',
  'Quote',
  function ($resource, $http, $q, Quote) {
    var API_ROOT_PATH = '/rest/api';
    $http.defaults.headers.common['Content-Type'] = 'application/json';
    var service = {
        auth: {},
        sync2back: {},
        configurator: {}
      };
    /**
     * Set root path for every api request.
     */
    service.getRootPath = function () {
      return API_ROOT_PATH;
    };
    /**
     * Returns server status.
     */
    service.status = function () {
      var d = $q.defer();
      var s = new Date().getTime();
      var r = 0, t = 0;
      $http({
        method: 'GET',
        url: API_ROOT_PATH + '/status/time'
      }).success(function (time) {
        r = new Date().getTime();
        t = parseInt(time, 10);
        d.resolve({
          online: true,
          latency: r - s,
          time: new Date(t)
        });
      }).error(function (message) {
        d.resolve({ online: false });
      });
      return d.promise;
    };
    /**
     * Creates and authorizes session.
     */
    service.auth.login = function (data) {
      return $http({
        method: 'POST',
        url: API_ROOT_PATH + '/auth/login',
        data: {
          'j_username': data.username,
          'j_password': data.password
        }
      });
    };
    /**
     *  Check if session still exists in backend.
     */
    service.auth.pollSession = function (username) {
      return $http({
        method: 'GET',
        url: API_ROOT_PATH + '/auth/login',
        transformResponse: function (data, headersGetter) {
          var response = false;
          if (data) {
            if (data.toUpperCase() == username.toUpperCase()) {
              response = true;
            } else {
              response = angular.fromJson(data);  //error message
            }
          }
          return response;
        }
      });
    };
    /**
     * Destroys current session (requires session cookie).
     */
    service.auth.logout = function () {
      return $http({
        method: 'GET',
        url: API_ROOT_PATH + '/auth/logout'
      });
    };
    /**
     * Users resource.
     */
    service.users = $resource(API_ROOT_PATH + '/users');
    service.users.changePassword = function (password) {
      return $http({
        method: 'POST',
        url: API_ROOT_PATH + '/users/changepassword',
        data: { 'j_password': password }
      });
    };
    /**
     * Catalogs resource.
     */
    service.catalogs = $resource(API_ROOT_PATH + '/sync/catalogs', { limit: 9999 });
    /**
     * Products resource.
     */
    service.products = $resource(API_ROOT_PATH + '/sync/catalogs/:catalogId/products', {
      catalogId: '@id',
      limit: 9999
    });
    /**
     * get product configuration
     */
    service.configurator.fetchConfiguration = function (data) {
      return $http({
        method: 'GET',
        url: API_ROOT_PATH + '/configurator/fetch-configuration',
        params: data
      });
    };
    /**
     * calculate pricing
     */
    service.configurator.calculatePricing = function (data) {
      return $http({
        method: 'POST',
        url: API_ROOT_PATH + '/configurator/calculate-pricing',
        data: data
      });
    };
    /**
     * request license file to be generated
     */
    service.configurator.requestLicense = function (data) {
      return $http({
        method: 'GET',
        url: API_ROOT_PATH + '/configurator/request-license',
        params: data
      });
    };
    /**
     * Get opportunity data from crm
     */
    service.getOpportunity = function (oppId) {
      return $http({
        method: 'GET',
        url: API_ROOT_PATH + '/user-quotes/crm/' + oppId
      });
    };
    /**
     * quote sync
     */
    service.syncUserQuote = function (quote) {
      return $http({
        method: 'PUT',
        url: API_ROOT_PATH + '/user-quotes/' + quote.guid + '/' + quote.quoteVersion,
        data: {
          quote: angular.toJson(quote),
          guid: quote.guid,
          version: quote.quoteVersion,
          type: quote.type,
          opportunityId: quote.opportunityId,
          deleted: quote.deleted,
          syncedCrm: quote.syncedCrm,
          lastChange: quote.updated
        }
      });
    };
    /**
     * syncs users quote with SAP;
     */
    service.syncQuoteSap = function (quote) {
      return $http({
        method: 'POST',
        url: API_ROOT_PATH + '/user-quotes/synchronise/' + quote.guid,
        data: {
          quote: angular.toJson(quote),
          guid: quote.guid,
          type: quote.type,
          deleted: quote.deleted,
          lastChange: quote.updated
        }
      });
    };
    service.syncQuoteCrm = function (quote) {
      console.log(quote);
      return $http({
        method: 'POST',
        url: API_ROOT_PATH + '/user-quotes/synchronise/crm/' + quote.guid,
        data: {
          quote: angular.toJson(quote),
          guid: quote.guid,
          version: quote.quoteVersion,
          type: quote.type,
          deleted: quote.deleted,
          lastChange: quote.updated
        }
      });
    };
    //service.quotes = $resource(API_ROOT_PATH + '/user-quotes', {});
    service.quotes = $resource(API_ROOT_PATH + '/user-quotes/:Id', { Id: '@id' }, {
      query: {
        method: 'GET',
        isArray: false,
        transformResponse: function (data, headersGetter) {
          var response = angular.fromJson(data);
          angular.forEach(response.items, function (item, i) {
            var quote = angular.fromJson(item.quote);
            response.items[i].quote = Quote.construct(quote, item.sapId, item.sapMessages, item.sapLastSync, item.licenseRequestedOn, item.crmLastSync, item.syncedCrmError, item.crmSyncMessages, item.crmnumber);
          });
          return response;
        }
      }
    });
    service.quote = function (guid, version) {
      return $http({
        method: 'GET',
        url: API_ROOT_PATH + '/user-quotes/quote/' + guid + '/' + version,
        transformResponse: function (data, headersGetter) {
          var response = angular.fromJson(data);
          response.quote = Quote.construct(angular.fromJson(response.quote), response.sapId, response.sapMessages, response.sapLastSync, response.licenseRequestedOn, response.crmLastSync, response.syncedCrmError, response.crmSyncMessages, response.crmnumber);
          return response;
        }
      });
    };
    service.quoteVersions = function (guid) {
      return $http({
        method: 'GET',
        url: API_ROOT_PATH + '/user-quotes/quote/' + guid,
        transformResponse: function (data, headersGetter) {
          var response = angular.fromJson(data);
          angular.forEach(response, function (item, i) {
            response[i].quote = Quote.construct(angular.fromJson(item.quote), item.sapId, item.sapMessages, item.sapLastSync, item.licenseRequestedOn, item.crmLastSync, item.syncedCrmError, item.crmSyncMessages, item.crmnumber);
          });
          return response;
        }
      });
    };
    service.quoteLastOpportunity = function (opportunityId) {
      return $http({
        method: 'GET',
        url: API_ROOT_PATH + '/user-quotes/quote/opportunity/' + opportunityId,
        transformResponse: function (data, headersGetter) {
          var response = angular.fromJson(data);
          if (response.hasOwnProperty('id')) {
            response.quote = Quote.construct(angular.fromJson(response.quote), response.sapId, response.sapMessages, response.sapLastSync, response.licenseRequestedOn, response.crmLastSync, response.syncedCrmError, response.crmSyncMessages, response.crmnumber);
          }
          return response;
        }
      });
    };
    /**
     * PLM Status resource.
     */
    service.plmStatus = $resource(API_ROOT_PATH + '/plm/:plmId', { plmId: '@id' }, {
      query: {
        method: 'GET',
        isArray: false,
        transformResponse: function (data, headersGetter) {
          var response = angular.fromJson(data);
          angular.forEach(response.items, function (item, i) {
            response.items[i] = new service.plmStatus(item);
          });
          return response;
        }
      }
    });
    service.plmStatus.sapSync = $resource(API_ROOT_PATH + '/plm/syncPlmStatus', {}, {
      query: {
        method: 'POST',
        isArray: false
      }
    });
    service.dimension1 = $resource(API_ROOT_PATH + '/dimension1/:dimension1Id', { dimension1Id: '@id' }, {
      query: {
        method: 'GET',
        isArray: false,
        transformResponse: function (data, headersGetter) {
          var response = angular.fromJson(data);
          angular.forEach(response.items, function (item, i) {
            response.items[i] = new service.BusinessLine(item);
          });
          return response;
        }
      }
    });
    service.Dimension2 = $resource(API_ROOT_PATH + '/dimension2/:dimension2Id', { dimension2Id: '@id' }, {
      query: {
        method: 'GET',
        isArray: false,
        transformResponse: function (data, headersGetter) {
          var response = angular.fromJson(data);
          angular.forEach(response.items, function (item, i) {
            response.items[i] = new service.Vertical(item);
          });
          return response;
        }
      }
    });
    service.Application = $resource(API_ROOT_PATH + '/dimension3/:dimension3Id', { dimension3Id: '@id' }, {
      query: {
        method: 'GET',
        isArray: false,
        transformResponse: function (data, headersGetter) {
          var response = angular.fromJson(data);
          angular.forEach(response.items, function (item, i) {
            response.items[i] = new service.Application(item);
          });
          return response;
        }
      }
    });
    return service;
  }
]);
'use strict';
angular.module('cpqFactoryApp').factory('User', [
  '$rootScope',
  'Storage',
  'md5',
  function ($rootScope, Storage, md5) {
    var hash = function (credentials) {
      var username = credentials.username, password = credentials.password;
      return md5.createHash(username + password);
    };
    var getActiveUser = function () {
      var userId = Storage.get('active');
      var users = Storage.get('users') || {};
      if (userId && userId in users) {
        return angular.extend(new User(), users[userId]);
      }
      return new User();
    };
    var getUserByCredentials = function (credentials) {
      var users = Storage.get('users') || {};
      var code = hash(credentials).toString();
      for (var userId in users) {
        var user = users[userId];
        if (user.login === credentials.username && user.code === code) {
          return user;
        }
      }
      return null;
    };
    var User = function () {
    };
    /**
         * Sets current user.
         *
         * @param {object} username and password
         * @param {object} user object from server
         */
    User.prototype.set = function (credentials, user) {
      angular.extend(this, getUserByCredentials(credentials));
      angular.extend(this, user);
      this.code = hash(credentials).toString();
      this.locked = false;
      this.dateformat = 'dd/MM/yyyy';
      this.save();
      // set scope
      $rootScope.user = this;
    };
    User.prototype.save = function () {
      //store in local storage
      var users = Storage.get('users') || {};
      users[this.id] = this;
      Storage.set('users', users);
      Storage.set('active', this.id);
    };
    /**
         * Logs out and removes user from persistent storage.
         */
    User.prototype.destroy = function () {
      var users = Storage.get('users') || {};
      for (var userId in users) {
        if (userId === this.id) {
          delete users[userId];
        }
      }
      for (var key in this) {
        if (typeof this[key] !== 'function') {
          delete this[key];
        }
      }
      Storage.set('users', users);
      Storage.remove('active');
      // set scope
      $rootScope.user = null;
    };
    /**
         * Prevents user from interacting with application.
         */
    User.prototype.lock = function () {
      this.locked = true;
      this.save();
      Storage.remove('active');
      // set scope
      $rootScope.user = null;
    };
    /**
         * Unlocks the application.
         */
    User.prototype.unlock = function (credentials) {
      var users = Storage.get('users') || {};
      var code = hash(credentials).toString();
      var result = null;
      for (var userId in users) {
        var user = users[userId];
        if (user.login === credentials.username) {
          result = false;
          if (user.code === code) {
            this.set(credentials, user);
            result = true;
          }
        }
      }
      return result;
    };
    User.prototype.isLoggedIn = function () {
      return this.id && !this.locked;
    };
    User.prototype.setUsedCatalogId = function (catalogId) {
      this.catalogId = catalogId;
      this.currency = null;
      this.save();
    };
    User.prototype.setUsedCurrency = function (currency) {
      this.currency = currency;
      this.save();
    };
    User.prototype.setCurrentQuote = function (guid, version) {
      this.currentQuoteGuid = guid;
      this.currentQuoteVersion = version;
      this.save();
    };
    User.prototype.resetCurrentQuote = function () {
      this.currentQuoteVersion = null;
      this.currentQuoteGuid = null;
      this.save();
    };
    User.prototype.setDateFormat = function (format) {
      this.dateformat = format;
      this.save();
    };
    User.prototype.getCurrentQuoteGuid = function () {
      return this.currentQuoteGuid;
    };
    User.prototype.getCurrentQuoteVersion = function () {
      return this.currentQuoteVersion;
    };
    User.prototype.getId = function () {
      return this.id;
    };
    User.prototype.getName = function () {
      return this.displayName;
    };
    User.prototype.getLogin = function () {
      return this.login;
    };
    User.prototype.getUsedCatalogId = function () {
      return this.catalogId;
    };
    User.prototype.getUsedCurrency = function () {
      return this.currency;
    };
    User.prototype.getDateFormat = function () {
      return this.dateformat;
    };
    /*User.prototype.loadQuote = function () {
            return this.quote;
        };*/
    User.prototype.hasRole = function (name) {
      var found = false;
      angular.forEach(this.roles, function (role) {
        if (role.name === name) {
          found = true;
        }
      });
      return found;
    };
    /**
         * Restore user from local storage.
         */
    var user = getActiveUser();
    if (user.getId()) {
      $rootScope.user = user;
    }
    return user;
  }
]);
'use strict';
angular.module('cpqFactoryApp').factory('AuthInterceptor', [
  '$q',
  '$rootScope',
  function ($q, $rootScope) {
    return function (promise) {
      return promise.then(function (response) {
        return response;
      }, function (response) {
        switch (response.status) {
        case 401:
          var config = response.config;
          if (config.url.indexOf('/auth/login') === -1) {
            $rootScope.$broadcast('user:destroy');
          }
          break;
        case 410:
          $rootScope.$broadcast('data:destroy');
          break;
        }
        return $q.reject(response);
      });
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').factory('Status', [
  '$q',
  'Api',
  '$rootScope',
  function ($q, Api, $rootScope) {
    var intId = 0;
    var service = {};
    /**
     * Updates server status.
     *
     * @param {number} update interval in miliseconds
     */
    service.poll = function (ms) {
      ms = isNaN(ms) || 3000;
      var defer = $q.defer();
      Api.status().then(function (response) {
        angular.extend(service, response);
        $rootScope.online = response.online;
        defer.resolve(response);
      });
      intId = setTimeout(function () {
        service.poll(ms);
      }, ms);
      return defer.promise;
    };
    /**
     * Stops the update process.
     */
    service.stop = function () {
      clearInterval(intId);
    };
    return service;
  }
]);
'use strict';
angular.module('indexedDB', []).provider('Data', function () {
  var indexedDB = window.indexedDB;
  var module = this;
  module.dbName = '';
  module.dbVersion = 0;
  module.useCompoundIndexes = true;
  module.dbUpgrade = function () {
  };
  module.setDatabase = function (name, version, upgrade) {
    module.dbName = name;
    module.dbVersion = version;
    module.dbUpgrade = upgrade;
  };
  module.formatIndex = function (index) {
    if (!module.useCompoundIndexes && angular.isArray(index)) {
      return index.join('_');
    }
    return index;
  };
  module.$get = [
    '$q',
    '$rootScope',
    function ($q, $rootScope) {
      var finish = function (defer, fn, e) {
        if (!$rootScope.$$phase) {
          $rootScope.$apply(function () {
            defer[fn](e);
          });
        } else {
          defer[fn](e);
        }
        ;
      };
      var open = function (name, version, defer) {
        if (!('indexedDB' in window)) {
          finish(defer, 'reject', e);
          return;
        }
        var req = indexedDB.open(name, version);
        req.addEventListener('error', function (e) {
          finish(defer, 'reject', e);
        }, true);
        req.addEventListener('success', function (e) {
          finish(defer, 'resolve', e.target.result);
        }, true);
        return req;
      };
      var service = {};
      service.isUsingcompoundIndexes = function () {
        return module.useCompoundIndexes;
      };
      /**
         * Creates and upgrades local databases.
         */
      service.upgrade = function () {
        var defer = $q.defer();
        var req = open(module.dbName, module.dbVersion, defer);
        req.addEventListener('upgradeneeded', function (e) {
          module.dbUpgrade.call(e.target.result, e);
          open(module.dbName, module.dbVersion, defer);
        }, true);
        return defer.promise;
      };
      /**
         * Deletes database with provided name.
         */
      service.deleteDatabase = function (dbName) {
        var defer = $q.defer();
        var req = indexedDB.deleteDatabase(dbName);
        req.addEventListener('error', function (e) {
          finish(defer, 'reject', e);
        }, true);
        req.addEventListener('success', function (e) {
          finish(defer, 'resolve', e);
        }, true);
        return defer.promise;
      };
      service.transaction = function (storeNames, mode) {
        return new TransactionWrapper(storeNames, mode);
      };
      service.store = function (storeName, mode) {
        return service.upgrade().then(function (db) {
          var tx = new TransactionWrapper(db, storeName, mode);
          return tx.store(storeName);
        });
      };
      /**
         * Constructor
         */
      var TransactionWrapper = function (db, storeNames, mode) {
        this._transaction = db.transaction(storeNames, mode || 'readonly');
      };
      /**
         * Object store getter.
         */
      TransactionWrapper.prototype.store = function (storeName) {
        var store = this._transaction.objectStore(storeName);
        return new StoreWrapper(store);
      };
      /**
         * Constructor
         */
      var StoreWrapper = function (store) {
        this._store = store;
      };
      /**
         * Attempts to store document and returns promise.
         */
      StoreWrapper.prototype.put = function (doc) {
        if (angular.isArray(doc)) {
          return this.putAll(doc);
        } else {
          return this.putOne(doc);
        }
      };
      /**
         * Stores one document.
         */
      StoreWrapper.prototype.putOne = function (doc) {
        var defer = $q.defer();
        var req = this._store.put(doc);
        req.addEventListener('success', function (e) {
          finish(defer, 'resolve', e.target.result);
        }, true);
        req.addEventListener('error', function (e) {
          finish(defer, 'reject', e);
        }, true);
        return defer.promise;
      };
      /**
         * Stores multiple documents in one transaction.
         */
      StoreWrapper.prototype.putAll = function (docs) {
        var defer = $q.defer();
        var store = this._store;
        var i = 0;
        var putNext = function () {
          if (docs && i < docs.length) {
            var req = store.put(docs[i]);
            req.addEventListener('success', putNext, true);
            req.addEventListener('error', function (e) {
              finish(defer, 'reject', e);
            }, true);
            i++;
          } else {
            finish(defer, 'resolve', docs);
          }
        };
        putNext();
        return defer.promise;
      };
      /**
         * Deletes all items in store.
         */
      StoreWrapper.prototype.clear = function () {
        var defer = $q.defer();
        var req = this._store.clear();
        req.addEventListener('error', function (e) {
          finish(defer, 'reject', e);
        }, true);
        req.addEventListener('success', function (e) {
          finish(defer, 'resolve', e);
        }, true);
        return defer.promise;
      };
      /**
         * Counts records.
         */
      StoreWrapper.prototype.count = function () {
        var defer = $q.defer();
        var store = this._store;
        var req = store.count();
        req.addEventListener('success', function (e) {
          finish(defer, 'resolve', e.target.result);
        }, true);
        req.addEventListener('error', function (e) {
          finish(defer, 'reject', e);
        }, true);
        return defer.promise;
      };
      /**
         * Gets all items from object store.
         * It uses native implementation if available.
         */
      StoreWrapper.prototype.getAll = function () {
        var defer = $q.defer();
        var store = this._store;
        var req, getAllFn;
        if ('getAll' in store) {
          getAllFn = store.getAll;
        } else if ('mozGetAll' in store) {
          getAllFn = store.mozGetAll;
        }
        if (getAllFn) {
          req = getAllFn.call(store);
          req.addEventListener('success', function (e) {
            finish(defer, 'resolve', e.target.result);
          }, true);
        } else {
          var items = [];
          req = store.openCursor();
          req.addEventListener('success', function (e) {
            var cursor = e.target.result;
            if (cursor) {
              items.push(cursor.value);
              cursor.continue();
            } else {
              finish(defer, 'resolve', items);
            }
          }, true);
        }
        req.addEventListener('error', function (e) {
          finish(defer, 'reject', e);
        }, true);
        return defer.promise;
      };
      /**
         * Deletes document from store by it's key.
         */
      StoreWrapper.prototype.delete = function (key) {
        var defer = $q.defer();
        var req = this._store.delete(key);
        req.addEventListener('error', function (e) {
          finish(defer, 'reject', e);
        }, true);
        req.addEventListener('success', function (e) {
          finish(defer, 'resolve', e);
        }, true);
        return defer.promise;
      };
      StoreWrapper.prototype.deleteByIndex = function (index, value) {
        var defer = $q.defer();
        var store = this._store;
        if (!module.useCompoundIndexes && angular.isArray(value)) {
          value = value.join('_');
        }
        var range = IDBKeyRange.only(value);
        var req = store.index(index).openCursor(range);
        req.addEventListener('success', function (e) {
          var cursor = e.target.result;
          if (cursor) {
            cursor.delete();
            cursor.continue();
          } else {
            finish(defer, 'resolve', e);
          }
        }, true);
        req.addEventListener('error', function (e) {
          finish(defer, 'reject', e);
        }, true);
        return defer.promise;
      };
      /**
         * Searches store for values by key or keys.
         */
      StoreWrapper.prototype.get = function (key) {
        var defer = $q.defer();
        var store = this._store;
        if (!module.useCompoundIndexes && angular.isArray(key)) {
          key = key.join('_');
        }
        var req = store.get(key);
        req.addEventListener('success', function (e) {
          finish(defer, 'resolve', e.target.result);
        }, true);
        req.addEventListener('error', function (e) {
          finish(defer, 'reject', e);
        }, true);
        return defer.promise;
      };
      /**
         * Searches store for value by index.
         */
      StoreWrapper.prototype.getByIndex = function (index, value) {
        var defer = $q.defer();
        var store = this._store;
        if (!module.useCompoundIndexes && angular.isArray(value)) {
          value = value.join('_');
        }
        var range = IDBKeyRange.only(value);
        var req = store.index(index).openCursor(range);
        var items = [];
        req.addEventListener('success', function (e) {
          var cursor = e.target.result;
          if (cursor) {
            items.push(cursor.value);
            cursor.continue();
          } else {
            finish(defer, 'resolve', items);
          }
        }, true);
        req.addEventListener('error', function (e) {
          finish(defer, 'reject', e);
        }, true);
        return defer.promise;
      };
      /**
         * Searches store for values by index in given range.
         */
      StoreWrapper.prototype.range = function (index, x, y) {
        var defer = $q.defer();
        var store = this._store;
        var range = IDBKeyRange.bound(x, y);
        var req = store.index(index).openCursor(range);
        var items = [];
        req.addEventListener('success', function (e) {
          var cursor = e.target.result;
          if (cursor) {
            items.push(cursor.value);
            cursor.continue();
          } else {
            finish(defer, 'resolve', items);
          }
        }, true);
        req.addEventListener('error', function (e) {
          finish(defer, 'reject', e);
        }, true);
        return defer.promise;
      };
      return service;
    }
  ];
});
'use strict';
angular.module('cpqFactoryApp').factory('Maintenance', [
  '$rootScope',
  '$q',
  '$route',
  '$templateCache',
  '$http',
  '$uibModal',
  'Status',
  'Api',
  'Storage',
  'Session',
  'Catalogs',
  'Categories',
  'Plmstates',
  'Products',
  'User',
  'Data',
  'Quotes',
  'Quote',
  function ($rootScope, $q, $route, $templateCache, $http, $uibModal, Status, Api, Storage, Session, Catalogs, Categories, Plmstates, Products, User, Data, Quotes, Quote) {
    var cache = 'applicationCache' in window ? window.applicationCache : null;
    var catalogsUpdateQueue = [];
    var cachedCatalogs = null;
    var cachedPlmStates = null;
    var cachedBusinessLines = null;
    var cachedVerticals = null;
    var cachedApplications = null;
    /*        var partialTempateUrls = [
            'views/partials/configure.html',
            'views/partials/options.html'
        ];*/
    var getCatalogs = function () {
      var defer = $q.defer();
      var userId = User.getId();
      if (cachedCatalogs) {
        defer.resolve(cachedCatalogs);
      } else {
        Api.catalogs.get(function (data) {
          for (var i = 0; i < data.items.length; i++) {
            data.items[i].userId = userId;
            if (!Data.isUsingcompoundIndexes()) {
              data.items[i].userId_id = [
                userId,
                data.items[i].id
              ].join('_');
            }
          }
          cachedCatalogs = data.items;
          defer.resolve(data.items);
        });
      }
      return defer.promise;
    };
    var getPlmStates = function () {
      var defer = $q.defer();
      var userId = User.getId();
      if (cachedPlmStates) {
        defer.resolve(cachedPlmStates);
      } else {
        Api.plmStatus.get(function (data) {
          for (var i = 0; i < data.items.length; i++) {
            data.items[i].userId = userId;
            if (!Data.isUsingcompoundIndexes()) {
              data.items[i].userId_id = [
                userId,
                data.items[i].id
              ].join('_');
            }
          }
          cachedPlmStates = data.items;
          defer.resolve(data.items);
        });
      }
      return defer.promise;
    };
    var pushNewCatalogsToUpdateQueue = function (allCatalogs) {
      var newCatalogs = allCatalogs[0], oldCatalogs = allCatalogs[1];
      angular.forEach(newCatalogs, function (newCatalog) {
        var found = false;
        angular.forEach(oldCatalogs, function (oldCatalog) {
          if (newCatalog.id === oldCatalog.id) {
            if (newCatalog.version > oldCatalog.version) {
              catalogsUpdateQueue.push(newCatalog);
            }
            found = true;
          }
        });
        // there was no old version, catalog is brand new
        if (!found) {
          catalogsUpdateQueue.push(newCatalog);
        }
      });
      return catalogsUpdateQueue;
    };
    var popCatalogsFromUpdateQueue = function () {
      var queue = angular.copy(catalogsUpdateQueue);
      catalogsUpdateQueue = [];
      return queue;
    };
    var getCatalogIdsFromUpdateQueue = function () {
      var catalogIds = [];
      angular.forEach(catalogsUpdateQueue, function (catalog) {
        catalogIds.push(catalog.id);
      });
      return catalogIds;
    };
    var getCategoriesFromCatalogs = function (catalogs) {
      var categories = [];
      var userId = User.getId();
      angular.forEach(catalogs, function (catalog) {
        angular.forEach(catalog.categories, function (category) {
          category.userId = userId;
          if (!Data.isUsingcompoundIndexes()) {
            category.userId_id = [
              userId,
              category.id
            ].join('_');
          }
          categories.push(category);
        });
      });
      return categories;
    };
    var getProductsFromCatalog = function (catalog) {
      var defer = $q.defer();
      var userId = User.getId();
      Api.products.get({ catalogId: catalog.id }, function (data) {
        for (var i = 0; i < data.items.length; i++) {
          data.items[i].userId = userId;
          data.items[i].catalogId = catalog.id;
        }
        defer.resolve(data.items);
      });
      return defer.promise;
    };
    var getProductsFromCatalogs = function (catalogs) {
      var defer = $q.defer();
      var allProducts = [];
      var cnt = catalogs.length;
      angular.forEach(catalogs, function (catalog) {
        if (!catalog.enabled) {
          if (--cnt <= 0) {
            defer.resolve(allProducts);
          }
          return;
        }
        getProductsFromCatalog(catalog).then(function (products) {
          angular.forEach(products, function (product) {
            if (!Data.isUsingcompoundIndexes()) {
              product.userId_catalogId_id = [
                product.userId,
                product.catalogId,
                product.id
              ].join('_');
              product.userId_catalogId = [
                product.userId,
                product.catalogId
              ].join('_');
              product.userId_catalogId_categoryId = [
                product.userId,
                product.catalogId,
                product.categoryId
              ].join('_');
            }
            allProducts.push(product);
          });
          if (--cnt <= 0) {
            defer.resolve(allProducts);
          }
        });
      });
      if (!catalogs.length) {
        defer.resolve(allProducts);
      }
      return defer.promise;
    };
    var service = {};
    /**
         * Updates database.
         */
    service.updateData = function () {
      cachedCatalogs = null;
      // This is why I love JavaScript!
      return $q.all([
        getCatalogs(),
        Catalogs.load(),
        this.syncQuotes()
      ]).then(pushNewCatalogsToUpdateQueue).then(getCatalogIdsFromUpdateQueue).then(Products.deleteByCatalogIds).then(Catalogs.clear).then(Categories.clear).then(getCatalogs).then(Catalogs.save).then(getCategoriesFromCatalogs).then(Categories.save).then(popCatalogsFromUpdateQueue).then(getProductsFromCatalogs).then(Products.save).then(this.Plmstates());
    };
    service.Plmstates = function () {
      Plmstates.clear().then(function () {
        getPlmStates().then(function (datas) {
          return Plmstates.save(datas);
        });
      });
    };
    /**
             * Deletes data from database.
             */
    service.deleteData = function () {
      return Catalogs.clear().then(Categories.clear).then(Products.clear);
    };
    /**
         * Deletes data from database.
         */
    service.deleteData = function () {
      return Catalogs.clear().then(Categories.clear).then(Products.clear);
    };
    /**
         * Deletes all session and storage data.
         */
    service.deleteStorage = function () {
      return $q.all([
        Storage.clear(),
        Session.clear()
      ]);
    };
    /**
         * Prefetch templates from server for performance.
         */
    /*        service.prefetchTemplates = function () {
            angular.forEach(partialTempateUrls, function (url) {
                if ($templateCache.get(url)) {
                    return;
                }
                $http.get(url).success(function (template) {
                    $templateCache.put(url, template);
                });
            });
        };*/
    /**
         * Tries to download new appcache manifest from the server.
         */
    /*        service.updateCache = function () {
            if (!cache) {
                return false;
            }
            if (cache.status === cache.IDLE && Status.online) {
                cache.update();
                return true;
            }
            return false;
        };*/
    service.syncSingleQuote = function (quote) {
      if (!quote.synced) {
        if (quote.deleted) {
          return Quotes.delete(quote);
        } else {
          return Quotes.save(quote, true);
        }
      }
      var promise = Api.syncUserQuote(quote);
      promise.success(function () {
        quote.synced = Date.now();
        if (quote.deleted) {
          Quotes.delete(quote);
        } else {
          Quotes.save(quote, true);
        }
      });
      promise.error(function (response) {
        quote.errors = response.errors;
        Quotes.save(quote, true);
      });
      return promise;
    };
    service.syncQuoteSap = function (quote) {
      var promise = Api.syncQuoteSap(quote);
      promise.success(function (response) {
        quote.synced = Date.now();
      });
      promise.error(function (response) {
        quote.errors = response.errors;
      });
      return promise;
    };
    service.syncQuoteCrm = function (quote) {
      var promise = Api.syncQuoteCrm(quote);
      promise.success(function (response) {
        quote.synced = Date.now();
      });
      promise.error(function (response) {
        quote.errors = response.errors;
      });
      return promise;
    };
    var saveLocaltoRemoteQuote = function (localQ) {
      Api.syncUserQuote(localQ).success(function (response) {
        //if (response.lastChange > localQ.updated) {
        var syncedQ = Quote.construct(angular.fromJson(response.quote), response.sapId, response.sapMessages, response.sapLastSync, response.licenseRequestedOn, response.crmLastSync);
        //}
        //delete from localstorage if the quote is deleted
        if (syncedQ.deleted == true) {
          Quotes.delete(localQ);
        } else {
          syncedQ.synced = Date.now();
          Quotes.save(syncedQ, false);
        }
      });
    };
    service.syncQuotes = function () {
      if (Status.online) {
        Quotes.loadQuotes().then(function (localQs) {
          angular.forEach(localQs, function (localQ) {
            var promise = Api.quote(localQ.guid, localQ.quoteVersion);
            promise.success(function (remoteQ) {
              if (remoteQ.licenseRequestedOn && (!localQ.licenseRequestedOn || remoteQ.licenseRequestedOn > localQ.licenseRequestedOn)) {
                localQ.licenseRequestedOn = remoteQ.licenseRequestedOn;
                Quotes.save(remoteQ.quote, false);
              }
              if (localQ.updated > remoteQ.lastChange) {
                //quote has been updated on client
                saveLocaltoRemoteQuote(localQ);
              } else {
                //was updated on server
                Quotes.save(remoteQ.quote, false);
              }
              ;
            });
            promise.error(function (response) {
              //quote does not exist on backend yet
              if (Object.keys(localQ.items).length > 0) {
                saveLocaltoRemoteQuote(localQ);
              }
            });
          });
          $rootScope.$emit('QUOTE_SYNC_FINISH');
        });
      }
      ;
    };
    if (cache) {
      cache.addEventListener('progress', function () {
        $rootScope.lockScreen('Loading...');
      }, false);
      /**
             * Display notification if application cache fails.
             */
      cache.addEventListener('error', function () {
        $rootScope.unlockScreen();
        if (!Status.online) {
          return;
        }
        $uibModal.open({
          templateUrl: 'views/modals/alert.html',
          controller: 'ModalsAlertCtrl',
          resolve: {
            title: function () {
              return 'AppCache Error';
            },
            message: function () {
              return 'Your browser does not support the offline functionality. ' + 'Use Chrome for the best offline experience. ';
            }
          }
        });
      }, false);
      /**
             * Automatically swap cache and reload when update is ready.
             */
      cache.addEventListener('updateready', function () {
        $rootScope.unlockScreen();
        /* cache.swapCache();*/
        $route.reload();
      }, false);
      cache.addEventListener('noupdate', function () {
        $rootScope.unlockScreen();
      }, false);
      cache.addEventListener('obsolete', function () {
        $rootScope.unlockScreen();
      }, false);
      cache.addEventListener('cached', function () {
        $rootScope.unlockScreen();
      }, false);
    }
    return service;
  }
]);
;
'use strict';
angular.module('cpqFactoryApp').factory('Session', function () {
  var s = window.sessionStorage;
  var service = {};
  service.get = function (key, options) {
    options = options || {};
    if (!('object' in options)) {
      options.object = true;
    }
    var data = s.getItem(key);
    if (options.object) {
      return JSON.parse(data);
    } else {
      return data;
    }
  };
  service.set = function (key, value) {
    if (angular.isObject(value)) {
      s.setItem(key, JSON.stringify(value));
    } else {
      s.setItem(key, value);
    }
  };
  service.remove = function (key) {
    s.removeItem(key);
  };
  service.clear = function () {
    s.clear.call(s);
  };
  return service;
});
'use strict';
angular.module('cpqFactoryApp').factory('Storage', function () {
  var s = window.localStorage;
  var service = {};
  service.get = function (key, options) {
    options = options || {};
    if (!('object' in options)) {
      options.object = true;
    }
    var data = s.getItem(key);
    if (options.object) {
      return JSON.parse(data);
    } else {
      return data;
    }
  };
  service.set = function (key, value) {
    if (angular.isObject(value)) {
      s.setItem(key, JSON.stringify(value));
    } else {
      s.setItem(key, value);
    }
  };
  service.remove = function (key) {
    s.removeItem(key);
  };
  service.clear = function () {
    s.clear.call(s);
  };
  return service;
});
'use strict';
angular.module('cpqFactoryApp').factory('Catalogs', [
  'Data',
  'User',
  function (Data, User) {
    var service = {};
    /**
     * Saves catalogs to database.
     */
    service.save = function (catalogs) {
      return Data.store('catalogs', 'readwrite').then(function (store) {
        return store.putAll(catalogs);
      });
    };
    /**
     * Loads catalogs from database.
     */
    service.load = function () {
      var userId = User.getId();
      return Data.store('catalogs').then(function (store) {
        return store.getByIndex('by_user', userId);
      });
    };
    /**
     * Deletes catalogs from database.
     */
    service.clear = function () {
      var userId = User.getId();
      return Data.store('catalogs', 'readwrite').then(function (store) {
        return store.deleteByIndex('by_user', userId);
      });
    };
    /**
     * Returns number of catalogs.
     */
    service.count = function () {
      var userId = User.getId();
      return Data.store('catalogs').then(function (store) {
        // quick colution, it can be done differently
        return store.getByIndex('by_user', userId).then(function (docs) {
          return docs.length;
        });
      });
    };
    /**
     * Gets catalog object by it's id.
     */
    service.getById = function (catalogId) {
      catalogId = parseInt(catalogId, 10);
      var userId = User.getId();
      return Data.store('catalogs').then(function (store) {
        return store.get([
          userId,
          catalogId
        ]);
      });
    };
    /**
     * Gets currently used catalog id.
     */
    service.getUsedCatalog = function () {
      var catalogId = User.getUsedCatalogId();
      if (catalogId) {
        return service.getById(catalogId).then(function (catalog) {
          if (catalog && catalog.enabled) {
            return catalog;
          }
          User.setUsedCatalogId(null);
          User.setUsedCurrency(null);
          return service.getUsedCatalog();
        });
      } else {
        return service.load().then(function (catalogs) {
          for (var i in catalogs) {
            var catalog = catalogs[i];
            if (catalog.enabled) {
              User.setUsedCatalogId(catalog.id);
              User.setUsedCurrency(catalog.currencies[0]);
              return service.getUsedCatalog();
            }
          }
          // no enabled catalogs
          return null;
        });
      }
    };
    /**
     * Sets active currency on used catalog.
     */
    service.getUsedCurrency = function () {
      return service.getUsedCatalog().then(function (catalog) {
        var currency = User.getUsedCurrency();
        // check that currency is in catalog
        if (catalog.currencies.indexOf(currency) !== -1) {
          return currency;
        }
        currency = catalog.currencies[0];
        return currency;
      });
    };
    return service;
  }
]);
'use strict';
angular.module('cpqFactoryApp').factory('Plmstates', [
  'Data',
  'User',
  function (Data, User) {
    var service = {};
    /**
     * Saves plmstates to database.
     */
    service.save = function (plmstates) {
      return Data.store('plmstates', 'readwrite').then(function (store) {
        return store.putAll(plmstates);
      });
    };
    /**
     * Loads plmstates from database.
     */
    service.load = function () {
      var userId = User.getId();
      return Data.store('plmstates').then(function (store) {
        return store.getByIndex('by_user', userId);
      });
    };
    /**
     * Deletes plmstates from database.
     */
    service.clear = function () {
      var userId = User.getId();
      return Data.store('plmstates', 'readwrite').then(function (store) {
        return store.deleteByIndex('by_user', userId);
      });
    };
    /**
     * Returns number of plmstates.
     */
    service.count = function () {
      var userId = User.getId();
      return Data.store('plmstates').then(function (store) {
        return store.countByIndex('by_user', userId);
      });
    };
    /**
     * Gets plmstate by id.
     */
    service.getplmstateById = function (plmstateId) {
      plmstateId = parseInt(plmstateId, 10);
      var userId = User.getId();
      return Data.store('plmstates').then(function (store) {
        return store.get([
          userId,
          plmstateId
        ]);
      });
    };
    return service;
  }
]);
'use strict';
angular.module('cpqFactoryApp').factory('Categories', [
  'Data',
  'User',
  function (Data, User) {
    var service = {};
    /**
     * Saves categories to database.
     */
    service.save = function (categories) {
      return Data.store('categories', 'readwrite').then(function (store) {
        return store.putAll(categories);
      });
    };
    /**
     * Loads categories from database.
     */
    service.load = function () {
      var userId = User.getId();
      return Data.store('categories').then(function (store) {
        return store.getByIndex('by_user', userId);
      });
    };
    /**
     * Deletes categories from database.
     */
    service.clear = function () {
      var userId = User.getId();
      return Data.store('categories', 'readwrite').then(function (store) {
        return store.deleteByIndex('by_user', userId);
      });
    };
    /**
     * Returns number of categories.
     */
    service.count = function () {
      var userId = User.getId();
      return Data.store('categories').then(function (store) {
        return store.countByIndex('by_user', userId);
      });
    };
    /**
     * Gets category by id.
     */
    service.getCategoryById = function (categoryId) {
      categoryId = parseInt(categoryId, 10);
      var userId = User.getId();
      return Data.store('categories').then(function (store) {
        return store.get([
          userId,
          categoryId
        ]);
      });
    };
    return service;
  }
]);
'use strict';
angular.module('cpqFactoryApp').factory('Products', [
  '$q',
  'Data',
  'Catalogs',
  'User',
  function ($q, Data, Catalogs, User) {
    var service = {};
    /**
     * Saves products to database.
     */
    service.save = function (products) {
      return Data.store('products', 'readwrite').then(function (store) {
        return store.putAll(products);
      });
    };
    /**
     * Loads products from database.
     */
    service.load = function () {
      var userId = User.getId();
      return Data.store('products').then(function (store) {
        return store.getByIndex('by_user', userId);
      });
    };
    /**
     * Deletes products from database.
     */
    service.clear = function () {
      var userId = User.getId();
      return Data.store('products', 'readwrite').then(function (store) {
        return store.deleteByIndex('by_user', userId);
      });
    };
    /**
     * Returns number of products.
     */
    service.count = function () {
      var userId = User.getId();
      return Data.store('products').then(function (store) {
        return store.countByIndex('by_user', userId);
      });
    };
    /**
     * Gets product list by category and currently used catalog.
     */
    service.getByCategoryId = function (categoryId) {
      categoryId = parseInt(categoryId, 10);
      var userId = User.getId();
      return Catalogs.getUsedCatalog().then(function (catalog) {
        return Data.store('products').then(function (store) {
          return store.getByIndex('by_user_catalog_and_category', [
            userId,
            catalog.id,
            categoryId
          ]);
        });
      });
    };
    /**
     * Deletes all products that belong to provided catalogs.
     */
    service.deleteByCatalogIds = function (catalogIds) {
      var userId = User.getId();
      var defer = $q.defer();
      return Data.store('products', 'readwrite').then(function (store) {
        var count = catalogIds.length;
        angular.forEach(catalogIds, function (catalogId) {
          store.deleteByIndex('by_user_and_catalog', [
            userId,
            catalogId
          ]).finally(function () {
            if (--count === 0) {
              defer.resolve();
            }
          });
        });
      });
      return defer.promise;
    };
    /**
     * Gets product by it's id.
     */
    service.getById = function (productId, catalogId) {
      productId = parseInt(productId, 10);
      var userId = User.getId();
      var res = null;
      if (catalogId) {
        catalogId = parseInt(catalogId, 10);
        res = Data.store('products').then(function (store) {
          return store.get([
            userId,
            catalogId,
            productId
          ]);
        });
      } else {
        res = Catalogs.getUsedCatalog().then(function (catalog) {
          return Data.store('products').then(function (store) {
            return store.get([
              userId,
              catalog.id,
              productId
            ]);
          });
        });
      }
      return res;
    };
    return service;
  }
]);
'use strict';
angular.module('cpqFactoryApp').factory('Quotes', [
  'Data',
  'User',
  'Quote',
  '$q',
  function (Data, User, Quote, $q) {
    var service = {};
    /**
     * Saves quotes to database.
     * Used for sync the number of uses of a certain productversion in a quotation
     */
    service.save = function (quote, notouch) {
      var userId = User.getId();
      return Data.store('quotes', 'readwrite').then(function (store) {
        if (!notouch) {
          quote.updated = Date.now();
        }
        quote.userId = userId;
        return store.putOne(quote);
      });
    };
    service.saveQuotes = function (quotes) {
      var userId = User.getId();
      if (quotes.length > 0) {
        return Data.store('quotes', 'readwrite').then(function (store) {
          angular.forEach(quotes, function (qt) {
            qt.userId = userId;
          });
          return store.putAll(quotes);
        });
      }
      ;
    };
    /**
     * Loads quotes from database.
     */
    service.loadQuotes = function () {
      var userId = User.getId();
      return Data.store('quotes').then(function (store) {
        return store.getByIndex('by_user', userId);
      });
    };
    service.loadCurrent = function () {
      var defer = $q.defer();
      var guid = User.getCurrentQuoteGuid();
      var quoteVersion = User.getCurrentQuoteVersion();
      var userId = User.getId();
      if (!guid) {
        defer.resolve();
        return defer.promise;
      }
      return Data.store('quotes').then(function (store) {
        return store.get([
          userId,
          guid,
          quoteVersion
        ]).then(function (quote) {
          return Quote.construct(quote);
        });
      });
    };
    service.loadQuoteVersion = function (guid, quoteVersion) {
      var defer = $q.defer();
      var userId = User.getId();
      if (!guid || !quoteVersion) {
        defer.resolve();
        return defer.promise;
      }
      if (typeof quoteVersion === 'string') {
        quoteVersion = Number(quoteVersion);
      }
      return Data.store('quotes').then(function (store) {
        return store.get([
          userId,
          guid,
          quoteVersion
        ]).then(function (quote) {
          if (quote) {
            return Quote.construct(quote);
          } else {
            return null;
          }
        });
      });
    };
    service.loadQuoteVersions = function () {
      var defer = $q.defer();
      var guid = User.getCurrentQuoteGuid();
      var userId = User.getId();
      if (!guid) {
        defer.resolve();
        return defer.promise;
      }
      return Data.store('quotes').then(function (store) {
        return store.getByIndex('by_user_and_guid', [
          userId,
          guid
        ]);
      });
    };
    service.delete = function (quote) {
      var userId = User.getId();
      var guid = quote.guid;
      var quoteVersion = quote.quoteVersion;
      if (User.getCurrentQuoteGuid() === guid) {
        User.resetCurrentQuote();
      }
      return service.deleteByKey([
        userId,
        guid,
        quoteVersion
      ]);
    };
    /**
     * Deletes quotes from database.
     */
    service.clear = function () {
      var userId = User.getId();
      return Data.store('quotes', 'readwrite').then(function (store) {
        return store.deleteByIndex('by_user', userId);
      });
    };
    service.deleteByKey = function (key) {
      return Data.store('quotes', 'readwrite').then(function (store) {
        return store.delete(key);
      });
    };
    /**
     * Returns number of quotes.
     */
    service.count = function (userId) {
      return Data.store('quotes').then(function (store) {
        return store.countByIndex('by_user', userId);
      });
    };
    return service;
  }
]);
'use strict';
angular.module('cpqFactoryApp').factory('Quote', [
  '$rootScope',
  '$q',
  'Catalogs',
  'Products',
  'QuoteItem',
  'User',
  function ($rootScope, $q, Catalogs, Products, QuoteItem, User) {
    /**
	 * Creates new quote.
	 */
    var createQuote = function (name) {
      return Catalogs.getUsedCatalog().then(function (catalog) {
        return Catalogs.getUsedCurrency().then(function (currency) {
          return new Quote(name, catalog, currency);
        });
      });
    };
    /**
	 * Construct quote from plain object.
	 */
    var constructQuote = function (obj, sapId, sapMessages, sapLastSync, licenseRequestedOn, crmLastSync, syncedCrmError, crmSyncMessages, crmnumber) {
      var quote = angular.extend(new Quote(), obj);
      if (sapLastSync && (!obj.sapLastSync || sapLastSync > obj.sapLastSync)) {
        quote.sapId = sapId;
        quote.sapMessages = sapMessages;
        quote.sapLastSync = sapLastSync;
      }
      if (licenseRequestedOn && (!quote.licenseRequestedOn || licenseRequestedOn > quote.licenseRequestedOn)) {
        quote.licenseRequestedOn = licenseRequestedOn;
      }
      if (crmLastSync && (!obj.crmLastSync || crmLastSync > obj.crmLastSync)) {
        quote.crmLastSync = crmLastSync;
      }
      if (syncedCrmError) {
        quote.syncedCrmError = syncedCrmError;
        quote.crmSyncMessages = crmSyncMessages;
      }
      if (crmnumber) {
        quote.crmnumber = crmnumber;
      }
      angular.forEach(quote.items, function (item, i) {
        if (item) {
          if (item.hasOwnProperty('product')) {
            var product = reviveProduct(item.product);
            quote.items[i] = new QuoteItem(item.id, product.name, product.description, product, item.quantity, item.type, item.discount, item.textdesc, item.textprice, item.serialNumbers, item.upgrade, item.originalOptions, item.originalPrice, item.unitPrice, item.tmpLicenseDate, item.tmpLicenseNo);
          }
        } else {
          quote.items.splice(i, 1);
        }
      });
      return quote;
    };
    /**
	 * Does some preprocessing on raw product model that was loaded from database.
	 */
    var raffineProduct = function (product, currency) {
      product = angular.copy(product);
      angular.forEach(product.optionGroups, function (optionGroup) {
        angular.forEach(optionGroup.optionCategories, function (optionCategory) {
          // if option category is not mandatory prepend empty option
          if (!optionCategory.mandatory) {
            optionCategory.options.unshift({ selectedByDefault: true });
          }
          angular.forEach(optionCategory.options, function (option) {
            // set used currency to option
            angular.forEach(option.prices, function (price) {
              if (currency === price.currency) {
                option.price = price;
              }
            });
            // set reference to selected option
            if (option.selectedByDefault) {
              optionCategory.selected = option;
            }
            ;
            // delete unused properties
            delete option.optionCategoryId;
            delete option.selectedByDefault;
            delete option.prices;
          });
          //remove "none" value selectable item for dropdown
          //if (!optionCategory.selected.hasOwnProperty('name')){
          angular.forEach(optionCategory.options, function (option, key) {
            if (!option.hasOwnProperty('name')) {
              optionCategory.options.splice(key, 1);
            }
            ;
          });  //}
        });
      });
      return product;
    };
    /**
	 * Revives product after it was loaded from local storage.
	 */
    var reviveProduct = function (product) {
      if (!product) {
        return product;
      }
      product = angular.copy(product);
      angular.forEach(product.optionGroups, function (optionGroup) {
        angular.forEach(optionGroup.optionCategories, function (optionCategory) {
          angular.forEach(optionCategory.options, function (option) {
            // restore internal reference because ng-options uses it for auto-selection
            if (angular.equals(optionCategory.selected, option)) {
              optionCategory.selected = option;
            }
          });
        });
      });
      return product;
    };
    /**
	 * Constructor
	 */
    var Quote = function (name, catalog, currency, hidediscount, type, sapId, sapMessages, deleted, opportunityId, opportunity, syncedCrm, quoteVersion, crmnumber) {
      // general quote properties
      this.name = name;
      this.version = 2;
      this.quoteVersion = 1;
      this.opportunity = opportunity;
      this.opportunity = opportunityId;
      this.created = Date.now();
      this.updated = Date.now();
      this.items = {};
      this.cardinality = 0;
      this.currency = currency || '';
      this.discount = 0;
      this.hidediscount = false;
      this.syncedCrm = syncedCrm;
      this.crmnumber = crmnumber || null;
      this.guid = this.generateGuid();
      // info about catalog this quote is using
      this.catalog = catalog;
      this.type = type ? type : 'ZQT';
      this.sapId = sapId;
      this.sapMessages = sapMessages;
      this.deleted = deleted ? deleted : false;
      //if deleted is false, then it will be false XD
      //  info about the user who originally created the quote
      this.user = {
        id: User.getId(),
        name: User.getName()
      };
      // info about the user and app who generated the quote
      this.generator = {
        appName: '',
        appVersion: 0,
        userId: 0,
        userName: '',
        time: null
      };
    };
    Quote.prototype.hideDiscount = function (hide) {
      this.hidediscount = hide;
    };
    Quote.prototype.generate = function () {
      this.generator.name = $rootScope.appName;
      this.generator.version = $rootScope.appVersion;
      this.generator.userId = User.getId();
      this.generator.userName = User.getName();
      this.generator.time = new Date();
    };
    Quote.prototype.generateGuid = function () {
      var d = Date.now();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == 'x' ? r : r & 7 | 8).toString(16);
        });
      return uuid;
    };
    Quote.prototype.getName = function () {
      return this.name;
    };
    Quote.prototype.getFileName = function () {
      return this.getName().toLowerCase().replace(/[|&;$%@"<>()+,]/g, '_').replace(/ /g, '_');
    };
    Quote.prototype.clone = function () {
      var copy = angular.copy(this);
      return constructQuote(copy);
    };
    /**
	 * Adds product to quote.
	 */
    Quote.prototype.addProduct = function (productId, quantity) {
      return Products.getById(productId).then(angular.bind(this, function (product) {
        var itemId = this.cardinality + 1;
        product = raffineProduct(product, this.currency);
        this.items[itemId] = new QuoteItem(itemId, product.name, product.description, product, quantity, 'CONF', 0, 0, '');
        this.items[itemId].productId = productId;
        this.cardinality++;
        this.updated = Date.now();
        return itemId;
      }));
    };
    Quote.prototype.addText = function () {
      var itemId = this.cardinality + 1;
      this.items[itemId] = new QuoteItem(itemId, '', '', {}, 1, 'TEXT', 0, 0, '', 0);
      this.cardinality++;
      this.updated = Date.now();
      return itemId;
    };
    Quote.prototype.addItem = function (item) {
      var itemId = this.cardinality + 1;
      item.id = itemId;
      this.items[itemId] = item;
      this.cardinality++;
      this.updated = Date.now();
      if (item.serialNumbers) {
        this.type = 'ZUQT';
      }
      return itemId;
    };
    /**
	 * Removes all items from quote.
	 */
    Quote.prototype.clear = function () {
      this.items = [];
      this.updated = Date.now();
      this.type = 'ZQT';
    };
    /**
	 * Gets an item by the index if you loop over this.items, so not by the itemId
	 */
    Quote.prototype.getItemByIndex = function (index) {
      var item;
      var loopindex = 0;
      for (var i in this.items) {
        item = this.items[i];
        if (loopindex === index) {
          return item;
        }
        loopindex += 1;
      }
      return null;
    };
    /**
	 * Gets item from specified index.
	 */
    Quote.prototype.getItem = function (itemId) {
      var item;
      for (var i in this.items) {
        item = this.items[i];
        if (item.id === itemId) {
          return item;
        }
      }
      return null;
    };
    /**
	 * Removes item at specified index.
	 */
    Quote.prototype.removeItem = function (itemId) {
      delete this.items[itemId];
      var newType = 'ZQT';
      this.updated = Date.now();
      angular.forEach(this.items, function (item) {
        if (typeof item.serialNumbers != 'undefined') {
          newType = 'ZUQT';
        }
      });
      this.type = newType;
    };
    Quote.prototype.getCurrency = function () {
      return this.currency;
    };
    Quote.prototype.countItems = function () {
      var cnt = 0;
      angular.forEach(this.items, function (item) {
        cnt++;
      });
      return cnt;
    };
    Quote.prototype.isUpgrade = function () {
      return this.type === 'ZUQT';
    };
    Quote.prototype.allowUpgradeItems = function () {
      return this.type === 'ZUQT' || this.countItems() === 0;
    };
    Quote.prototype.allowRegularItems = function () {
      return this.type === 'ZQT' || this.countItems() === 0;
    };
    Quote.prototype.countProducts = function () {
      var cnt = 0;
      angular.forEach(this.items, function (item) {
        cnt += item.quantity;
      });
      return cnt;
    };
    Quote.prototype.validate = function () {
      var quote = this;
      return Catalogs.getById(quote.catalog.id).then(function (catalog) {
        var result = {
            unavailableCatalog: false,
            unavailableCurrency: false,
            outdatedCatalog: false,
            valid: true
          };
        if (!catalog) {
          result.unavailableCatalog = true;
          result.valid = false;
        } else if (catalog.currencies.indexOf(quote.currency) === -1) {
          result.unavailableCurrency = true;
          result.valid = false;
        } else if (catalog.version > quote.catalog.version) {
          result.outdatedCatalog = true;
          result.valid = false;
        }
        return result;
      });
    };
    Quote.prototype.update = function () {
      var quote = this;
      var defer = $q.defer();
      Catalogs.getById(quote.catalog.id).then(function (catalog) {
        // update version number
        return quote.catalog.version = catalog.version;
      }).then(function (version) {
        // replace producs with new ones
        var counter = 1;
        var itemCount = quote.countItems();
        var items = angular.extend(quote.items);
        var currency = quote.currency;
        var updateLog = {
            version: version,
            removedItems: [],
            updatedItems: []
          };
        quote.updated = Date.now();
        angular.forEach(items, function (item) {
          Products.getById(item.product.id, item.product.catalogId).then(function (product) {
            if (product) {
              // update item returns missing options
              var itemLog = item.update(raffineProduct(product, currency));
              item.missingOptions = itemLog.missingOptions;
              updateLog.updatedItems.push(item);
            } else {
              // reomove item
              quote.removeItem(item.id);
              updateLog.removedItems.push(item);
            }
          }).finally(function () {
            if (counter === itemCount) {
              defer.resolve(updateLog);
            }
            counter++;
          });
        });
      });
      return defer.promise;
    };
    /**
	 * Pricing functions.
	 */
    Quote.prototype.getTotalPrice = function () {
      var totalPrice = this.getItemsTotalPrice();
      if (this.discount) {
        totalPrice -= this.getProjectDiscountPrice();
      }
      return totalPrice;
    };
    Quote.prototype.getProjectDiscountPrice = function () {
      var discount = 0;
      if (this.discount) {
        discount += parseFloat(this.discount);
      }
      return this.discount;
    };
    Quote.prototype.getItemsTotalPrice = function () {
      var sum = 0;
      angular.forEach(this.items, function (item) {
        if (item.type == 'CONF' && item.isPriceReady()) {
          sum += item.getEquipmentTotalPrice();
        }
      });
      //add other items
      sum += this.getItemTextTotalPrice();
      return sum;
    };
    //Get price of all other items seperatly
    Quote.prototype.getItemTextTotalPrice = function () {
      var sum = 0;
      angular.forEach(this.items, function (item) {
        if (item.type == 'TEXT') {
          sum += item.getTextPrice();
        }
      });
      return sum;
    };
    Quote.prototype.getItems = function () {
      var items = [];
      angular.forEach(this.items, function (item) {
        items.push(item);
      });
      return items;
    };
    Quote.prototype.moveItem = function (index, type) {
      if (type == 'down') {
        var movedown = angular.copy(this.getItemByIndex(index));
        var moveup = angular.copy(this.getItemByIndex(index + 1));
      } else if (type == 'up') {
        var movedown = angular.copy(this.getItemByIndex(index - 1));
        var moveup = angular.copy(this.getItemByIndex(index));
      }
      var movedownId = movedown.id;
      var moveupId = moveup.id;
      this.removeItem(moveupId);
      this.removeItem(movedownId);
      moveup.id = movedownId;
      movedown.id = moveupId;
      this.items[moveup.id] = moveup;
      this.items[movedown.id] = movedown;
    };
    Quote.prototype.setOpportunityData = function (oppId, opportunity) {
      console.log('setOpportunityData');
      var quote = this;
      var defer = $q.defer();
      this.name = opportunity.name + ' for ' + opportunity.account.name;
      this.opportunity = opportunity;
      this.opportunityId = oppId;
      defer.resolve(quote);
      return defer.promise;
    };
    Quote.prototype.removeCrmData = function () {
      this.opportunity = null;
      this.opportunityId = null;
      this.syncedCrm = false;
      this.syncedCrmError = false;
      this.crmLastSync = null;
      this.crmSyncMessages = null;
      this.crmnumber = null;
    };
    var service = {
        create: createQuote,
        construct: constructQuote
      };
    return service;
  }
]);
'use strict';
angular.module('cpqFactoryApp').factory('QuoteItem', [
  'User',
  function (User) {
    /**
     * Constructor
     */
    var QuoteItem = function (id, name, description, product, quantity, type, discount, textdesc, textprice, serialNumbers, upgrade, originalOptions, originalPrice, unitPrice, tmpLicenseDate, tmpLicenseNo) {
      this.id = id;
      this.type = type || 'CONF';
      this.name = name;
      this.description = description;
      this.product = product;
      this.quantity = quantity || 0;
      this.discount = discount || 0;
      this.textdesc = textdesc ? textdesc : product ? product.name : '';
      this.textprice = textprice || 0;
      this.momentdateformat = 'L';
      //dateformat dd/MM/yyyy
      this.serialNumbers = serialNumbers;
      this.unitPrice = unitPrice;
      this.tmpLicenseDate = tmpLicenseDate || null;
      this.tmpLicenseNo = tmpLicenseNo || 0;
      if (upgrade) {
        this.setUpgrade(serialNumbers, upgrade);
      }
      this.originalOptions = originalOptions;
      this.originalOptionsMissingCodes = null;
      if (this.originalOptions) {
        var missingOptions = angular.copy(originalOptions);
        this.iterateOptionCategories(function (optionCategory) {
          optionCategory.originalSalesCode = null;
          angular.forEach(optionCategory.options, function (option) {
            if (originalOptions.indexOf(option.salesCode) > -1) {
              optionCategory.originalSalesCode = option.salesCode;
              missingOptions.splice(missingOptions.indexOf(option.salesCode), 1);
            }
          });
        });
        if (missingOptions && missingOptions.length > 0) {
          this.originalOptionsMissingCodes = missingOptions;
        }
      }
      this.originalPrice = originalPrice;
      //this defaults to true since initially there are no changes
      //expect if somehow the item is saved with serialNumbers defined, but upgrade not yet available
      //when an option is changed, it will be set to false
      //when CalculatePricing is called, will be set back to true;
      this.upgradeCostCalculated = !!this.serialNumbers && this.upgrade;
    };
    QuoteItem.prototype.isUpgradeItem = function () {
      return !!this.serialNumbers;
    };
    QuoteItem.prototype.update = function (product) {
      var selected = this.getSelectedSaleCodes();
      var additional = this.getSelectedAdditionalSaleCodes();
      var item = this;
      // update name and description
      this.name = product.name;
      this.description = product.description;
      // replace product structure
      this.product = product;
      var updateLog = { missingOptions: [] };
      // reconfigure product options
      item.unselectAllOptions();
      angular.forEach(selected, function (salesCode) {
        if (!item.selectOption(salesCode)) {
          updateLog.missingOptions.push(salesCode);
        }
      });
      angular.forEach(additional, function (salesCode) {
        if (!item.selectAdditionalOption(salesCode)) {
          updateLog.missingOptions.push(salesCode);
        }
      });
      return updateLog;
    };
    QuoteItem.prototype.unselectAllOptions = function () {
      this.iterateOptionCategories(function (optionCategory) {
        optionCategory.selected = {};
      });
    };
    QuoteItem.prototype.selectOption = function (salesCode) {
      var codeFound = false;
      this.iterateOptionCategories(function (optionCategory) {
        angular.forEach(optionCategory.options, function (option) {
          if (option.salesCode === salesCode) {
            optionCategory.selected = option;
            codeFound = true;
          }
        });
      });
      return codeFound;
    };
    QuoteItem.prototype.selectOriginalOption = function (salesCode) {
      var codeFound = false;
      if (!this.originalOptions) {
        this.originalOptions = [];
      }
      this.originalOptions.push(salesCode);
      this.iterateOptionCategories(function (optionCategory) {
        angular.forEach(optionCategory.options, function (option) {
          if (option.salesCode === salesCode) {
            optionCategory.selected = option;
            optionCategory.originalSalesCode = salesCode;
            codeFound = true;
          }
        });
      });
      if (!codeFound) {
        if (!this.originalOptionsMissingCodes) {
          this.originalOptionsMissingCodes = [];
        }
        this.originalOptionsMissingCodes.push(salesCode);
      }
      return codeFound;
    };
    QuoteItem.prototype.selectAdditionalOption = function (salesCode) {
      var codeFound = false;
      this.iterateOptionCategories(function (optionCategory) {
        angular.forEach(optionCategory.options, function (option) {
          if (option.salesCode === salesCode) {
            option.additional = true;
            codeFound = true;
          }
        });
      });
      return codeFound;
    };
    QuoteItem.prototype.iterateOptionCategories = function (callback, options) {
      angular.forEach(this.product.optionGroups, function (optionGroup) {
        angular.forEach(optionGroup.optionCategories, function (optionCategory) {
          callback.call(this, optionCategory);
        });
      });
    };
    QuoteItem.prototype.replaceAll = function (string, find, replace) {
      return string.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    };
    QuoteItem.prototype.escapeRegExp = function (string) {
      return string.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    };
    QuoteItem.prototype.validateItem = function () {
      var valid = true;
      //if this is an upgrade item and the price is not ready
      if (this.isUpgradeItem() && !this.isPriceReady()) {
        valid = false;
      }
      if (valid == true) {
        valid = this.validateRequiredOptions();
      }
      return valid;
    };
    QuoteItem.prototype.getSapMatnr = function () {
      var sapmatnr = null;
      angular.forEach(this.product.productVersions, function (v) {
        if (v.active == true && v.matnr && v.matnr != 'N/A') {
          sapmatnr = v.matnr;
        }
        ;
      });
      return sapmatnr;
    };
    QuoteItem.prototype.validateRequiredOptions = function () {
      var selectedCodes = this.getSelectedSaleCodes();
      var allCodes = this.getAllSaleCodes();
      var isError = false;
      this.iterateOptionCategories(function (optionCategory) {
        if (optionCategory.selected != null) {
          var selected = optionCategory.selected;
          var constraints = [
              {
                expression: selected.requiredOptions,
                expectedResult: true,
                message: selected.requiredErrorText || 'This option requires some other option selected!'
              },
              {
                expression: selected.incompatibleOptions,
                expectedResult: false,
                message: selected.incompatibleErrorText || 'This option is incompatible with some other option!'
              }
            ];
          // reset status
          optionCategory.error = null;
          angular.forEach(constraints, function (constraint) {
            var expression = constraint.expression;
            // not all options have constraints
            if (!expression) {
              return;
            }
            // replace codes with true/false expressions
            angular.forEach(allCodes, function (code) {
              if (selectedCodes.indexOf(code) !== -1) {
                //replace all values of code in string
                expression = expression.split(code).join('true');
              } else {
                expression = expression.split(code).join('false');
              }
            });
            expression = expression.replace(/\|/g, '||').replace(/\&/g, '&&');
            try {
              if (eval(expression) !== constraint.expectedResult) {
                optionCategory.error = constraint.message;
                isError = true;
              }
            } catch (error) {
              optionCategory.error = 'There was an error while parsing constraint!';
              isError = true;
            }
          });
        }
        ;
      });
      // additional fields are already validated by HTML5 regex validation
      if (this.discount === undefined) {
        isError = true;
      }
      if (this.quantity === undefined) {
        isError = true;
      }
      return !isError;
    };
    QuoteItem.prototype.getSelectedSaleCodes = function () {
      var codes = [];
      this.iterateOptionCategories(function (optionCategory) {
        if (optionCategory.selected != null) {
          if (optionCategory.selected.salesCode) {
            codes.push(optionCategory.selected.salesCode);
          }
          ;
        }
        ;
      });
      return codes;
    };
    QuoteItem.prototype.isOriginalOption = function (category) {
      if (!this.originalOptions) {
        //cannot be part of originalOptions when there are no original options!
        return false;
      }
      if (category.selected && category.selected.salesCode) {
        return this.originalOptions.indexOf(category.selected.salesCode) > -1;
      }
      //if selected/salesCode is null, then check the original
      return !category.originalSalesCode;
    };
    QuoteItem.prototype.getSelectedAdditionalSaleCodes = function () {
      var codes = [];
      this.iterateOptionCategories(function (optionCategory) {
        if (optionCategory.selected.additional) {
          codes.push(optionCategory.selected.salesCode);
        }
      });
      return codes;
    };
    QuoteItem.prototype.getAllSaleCodes = function () {
      var codes = [];
      this.iterateOptionCategories(function (optionCategory) {
        angular.forEach(optionCategory.options, function (option) {
          codes.push(option.salesCode);
        });
      });
      return codes;
    };
    QuoteItem.prototype.getCategoriesWithAdditionalOptions = function () {
      var categories = [], category, j, i;
      angular.forEach(this.product.optionGroups, function (group) {
        for (j = 0; j < group.optionCategories.length; j++) {
          category = group.optionCategories[j];
          for (i = 0; i < category.options.length; i++) {
            if (category.options[i].additional) {
              categories.push(category);
              break;
            }
          }
        }
      });
      return categories;
    };
    QuoteItem.prototype.hasAdditionalOptions = function () {
      var group, category, k, j, i;
      for (k = 0; k < this.product.optionGroups.length; k++) {
        group = this.product.optionGroups[k];
        for (j = 0; j < group.optionCategories.length; j++) {
          category = group.optionCategories[j];
          for (i = 0; i < category.options.length; i++) {
            if (category.options[i].additional) {
              return true;
            }
          }
        }
      }
      return false;
    };
    QuoteItem.prototype.countCategoryAdditionalOptions = function (category) {
      var cnt = 0, i;
      for (i = 0; i < category.options.length; i++) {
        if (category.options[i].additional) {
          cnt++;
        }
      }
      return cnt;
    };
    QuoteItem.prototype.countGroupSelectedOptionCategories = function (group, options, inverted) {
      var cnt = 0;
      angular.forEach(group.optionCategories, function (category) {
        cnt++;
      });
      return cnt;
    };
    QuoteItem.prototype.countGroupOptionCategories = function (group, options, inverted) {
      var cnt = 0;
      angular.forEach(group.optionCategories, function (category) {
        cnt++;
      });
      return cnt;
    };
    QuoteItem.prototype.countCategoryOptions = function (category) {
      var cnt = 0;
      for (var i = 0; i < category.options.length; i++) {
        if ('id' in category.options[i]) {
          cnt++;
        }
      }
      return cnt;
    };
    QuoteItem.prototype.isFirstShipdateInFuture = function () {
      var future = false;
      if (this.product.frstshpDate) {
        var dateobj = new Date(this.product.frstshpDate);
        var today = new Date();
        if (dateobj - today > 0) {
          future = true;
        }
      }
      return future;
    };
    QuoteItem.prototype.getFirstShipDateDesc = function () {
      var date = new Date(this.product.frstshpDate);
      return moment(date).format(User.getDateFormat().toUpperCase());
    };
    QuoteItem.prototype.isPriceReady = function () {
      //no quantity
      if (this.quantity == 0 || typeof this.quantity == 'undefined') {
        return false;
      }
      //if this is not an upgrade item, return true
      if (!this.isUpgradeItem()) {
        return true;
      }
      //otherwise, check if there were changes whose cost was calculated
      return !!this.upgrade && this.upgradeCostCalculated;
    };
    QuoteItem.prototype.getUnitNetValue = function () {
      if (this.isUpgradeItem() && this.upgrade) {
        return this.upgrade.totalNetValue;
      }
      return 0;
    };
    /**
     * Pricing functions...
     */
    QuoteItem.prototype.getUnitPrice = function () {
      var sum = 0;
      if (this.isPriceReady()) {
        if (this.isUpgradeItem()) {
          return this.getUnitNetValue();
        } else {
          this.iterateOptionCategories(function (optionCategory) {
            if (optionCategory.selected == null) {
              return;
            }
            ;
            if (!('price' in optionCategory.selected)) {
              return;
            }
            var price = optionCategory.selected.price;
            if (!price.percentage) {
              sum += price.value;
            }
          }, {});
        }
      }
      this.unitPrice = sum;
      return sum;
    };
    QuoteItem.prototype.getListPrice = function () {
      if (!this.isPriceReady()) {
        return 0;
      }
      var price = this.getUnitPrice();
      return this.quantity * price;
    };
    QuoteItem.prototype.getEquipmentTotalPrice = function () {
      if (!this.isPriceReady()) {
        return 0;
      }
      var price = this.getListPrice();
      if (this.discount) {
        price -= price * (this.discount / 100);
      }
      return price;
    };
    QuoteItem.prototype.getUnitTotalPrice = function () {
      if (!this.isPriceReady()) {
        return 0;
      }
      var price = this.getUnitPrice();
      if (this.discount) {
        price -= price * (this.discount / 100);
      }
      return price;
    };
    QuoteItem.prototype.getCategoriesTotalPrice = function (type) {
      if (!this.isPriceReady()) {
        return 0;
      }
      var sum = 0;
      var listPrice = this.getListPrice();
      this.iterateOptionCategories(function (optionCategory) {
        if (!('price' in optionCategory.selected)) {
          return;
        }
        var price = optionCategory.selected.price;
        if (price.percentage) {
          sum += listPrice * (price.value / 100);
        } else {
          sum += price.value;
        }
      }, {});
      return sum;
    };
    QuoteItem.prototype.getTextPrice = function () {
      if (!this.isPriceReady()) {
        return 0;
      }
      var price = 0;
      if (this.textprice) {
        price += this.quantity * parseFloat(this.textprice);
      }
      return price;
    };
    QuoteItem.prototype.getTotalPrice = function () {
      if (!this.isPriceReady()) {
        return 0;
      }
      return this.getEquipmentTotalPrice();
    };
    QuoteItem.prototype.setUpgrade = function (serialNumbers, upgrade) {
      this.serialNumbers = serialNumbers;
      this.upgrade = upgrade;
      this.quantity = parseInt(upgrade.quantity, 10);
      this.upgradeCostCalculated = true;
      this.upgradeDiffs = {};
      for (var i = 0; i < this.upgrade.mapping.length; i++) {
        var info = this.upgrade.mapping[i];
        if (info.oldPrice && info.oldPrice.substr(info.oldPrice.length - 1) === '-') {
          info.oldPrice = info.oldPrice.substring(0, info.oldPrice.length - 1);
        }
        var priceDiff = info.newPrice - info.oldPrice;
        if (priceDiff != 0) {
          this.upgradeDiffs[info.newSalesCode] = priceDiff;
        }
      }
    };
    QuoteItem.prototype.cancelUpgrade = function () {
      this.upgrade = null;
      if (this.isUpgradeItem()) {
        this.upgradeCostCalculated = false;
      }
    };
    QuoteItem.prototype.getOptionUpgradePrice = function (category) {
      if (!this.isPriceReady()) {
        return null;
      }
      if (!this.upgrade) {
        return null;
      }
      for (var i = 0; i < this.upgrade.mapping.length; i++) {
        var info = this.upgrade.mapping[i];
        if (category.selected != null) {
          if (category.selected.salesCode) {
            if (info.newSalesCode === category.selected.salesCode) {
              info.diff = info.newPrice - info.oldPrice;
              info.absDiff = Math.abs(info.diff);
              return info;
            }
          } else if (category.originalSalesCode === info.oldSalesCode) {
            info.diff = info.newPrice - info.oldPrice;
            info.absDiff = Math.abs(info.diff);
            return info;
          }
        }
      }
      return null;
    };
    return QuoteItem;
  }
]);
'use strict';
angular.module('utils', []).provider('Probe', function () {
  var module = this;
  var requiredFeatures = [
      'indexedDB',
      'localStorage',
      'sessionStorage'
    ];
  module.getInternetExplorerVersion = function () {
    var rv = 0;
    if (navigator.appName == 'Microsoft Internet Explorer') {
      var ua = navigator.userAgent;
      var re = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
      if (re.exec(ua) != null)
        rv = parseFloat(RegExp.$1);
    } else if (navigator.appName == 'Netscape') {
      var ua = navigator.userAgent;
      var re = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})');
      if (re.exec(ua) != null)
        rv = parseFloat(RegExp.$1);
    }
    return rv;
  };
  module.detect = function () {
    for (var i in requiredFeatures) {
      var prop = requiredFeatures[i];
      if (!module[prop]()) {
        return false;
      }
    }
    if (module.isUnsupportedBrowser()) {
      return false;
    }
    return true;
  };
  module.indexedDB = function () {
    return 'indexedDB' in window;
  };
  module.applicationCache = function () {
    return 'applicationCache' in window;
  };
  module.localStorage = function () {
    return 'localStorage' in window;
  };
  module.sessionStorage = function () {
    return 'sessionStorage' in window;
  };
  module.isUnsupportedBrowser = function () {
    var version = module.getInternetExplorerVersion();
    return version;
  };
  // make available as service
  module.$get = [function () {
      return angular.copy(module);
    }];
});
'use strict';
angular.module('cpqFactoryApp').factory('_', function () {
  return window._;
});
/**
 * A saveAs() FileSaver implementation.
 * 2014-01-24
 *
 * By Eli Grey, http://eligrey.com
 * This software is licensed under the MIT/X11 license.
 * 
 * MIT/X11 license
 * ---------------
 * 
 * Copyright 2011 Eli Grey.
 * 
 * Permission is hereby granted, free of charge, to any person
 * obtaining a copy of this software and associated documentation
 * files (the "Software"), to deal in the Software without
 * restriction, including without limitation the rights to use,
 * copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the
 * Software is furnished to do so, subject to the following
 * conditions:
 * 
 * The above copyright notice and this permission notice shall be
 * included in all copies or substantial portions of the Software.
 * 
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES
 * OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
 * NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT
 * HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY,
 * WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING
 * FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR
 * OTHER DEALINGS IN THE SOFTWARE.
 *
 * https://github.com/eligrey/FileSaver.js
 */
angular.module('cpqFactoryApp').factory('FileSaver', [function () {
    var saveAs = saveAs || navigator.msSaveOrOpenBlob && navigator.msSaveOrOpenBlob.bind(navigator) || function (view) {
        'use strict';
        // IE <10 is explicitly unsupported
        if (/MSIE [1-9]\./.test(navigator.userAgent)) {
          return;
        }
        var doc = view.document, get_URL = function () {
            return view.URL || view.webkitURL || view;
          }, URL = view.URL || view.webkitURL || view, save_link = doc.createElementNS('http://www.w3.org/1999/xhtml', 'a'), can_use_save_link = !view.externalHost && 'download' in save_link, click = function (node) {
            var event = doc.createEvent('MouseEvents');
            event.initMouseEvent('click', true, false, view, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            node.dispatchEvent(event);
          }, webkit_req_fs = view.webkitRequestFileSystem, req_fs = view.requestFileSystem || webkit_req_fs || view.mozRequestFileSystem, throw_outside = function (ex) {
            (view.setImmediate || view.setTimeout)(function () {
              throw ex;
            }, 0);
          }, force_saveable_type = 'application/octet-stream', fs_min_size = 0, deletion_queue = [], process_deletion_queue = function () {
            var i = deletion_queue.length;
            while (i--) {
              var file = deletion_queue[i];
              if (typeof file === 'string') {
                // file is an object URL
                URL.revokeObjectURL(file);
              } else {
                // file is a File
                file.remove();
              }
            }
            deletion_queue.length = 0;  // clear queue
          }, dispatch = function (filesaver, event_types, event) {
            event_types = [].concat(event_types);
            var i = event_types.length;
            while (i--) {
              var listener = filesaver['on' + event_types[i]];
              if (typeof listener === 'function') {
                try {
                  listener.call(filesaver, event || filesaver);
                } catch (ex) {
                  throw_outside(ex);
                }
              }
            }
          }, FileSaver = function (blob, name) {
            // First try a.download, then web filesystem, then object URLs
            var filesaver = this, type = blob.type, blob_changed = false, object_url, target_view, get_object_url = function () {
                var object_url = get_URL().createObjectURL(blob);
                deletion_queue.push(object_url);
                return object_url;
              }, dispatch_all = function () {
                dispatch(filesaver, 'writestart progress write writeend'.split(' '));
              }  // on any filesys errors revert to saving with object URLs
, fs_error = function () {
                // don't create more object URLs than needed
                if (blob_changed || !object_url) {
                  object_url = get_object_url(blob);
                }
                if (target_view) {
                  target_view.location.href = object_url;
                } else {
                  window.open(object_url, '_blank');
                }
                filesaver.readyState = filesaver.DONE;
                dispatch_all();
              }, abortable = function (func) {
                return function () {
                  if (filesaver.readyState !== filesaver.DONE) {
                    return func.apply(this, arguments);
                  }
                };
              }, create_if_not_found = {
                create: true,
                exclusive: false
              }, slice;
            ;
            filesaver.readyState = filesaver.INIT;
            if (!name) {
              name = 'download';
            }
            if (can_use_save_link) {
              object_url = get_object_url(blob);
              // FF for Android has a nasty garbage collection mechanism
              // that turns all objects that are not pure javascript into 'deadObject'
              // this means `doc` and `save_link` are unusable and need to be recreated
              // `view` is usable though:
              doc = view.document;
              save_link = doc.createElementNS('http://www.w3.org/1999/xhtml', 'a');
              save_link.href = object_url;
              save_link.download = name;
              var event = doc.createEvent('MouseEvents');
              event.initMouseEvent('click', true, false, view, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
              save_link.dispatchEvent(event);
              filesaver.readyState = filesaver.DONE;
              dispatch_all();
              return;
            }
            // Object and web filesystem URLs have a problem saving in Google Chrome when
            // viewed in a tab, so I force save with application/octet-stream
            // http://code.google.com/p/chromium/issues/detail?id=91158
            if (view.chrome && type && type !== force_saveable_type) {
              slice = blob.slice || blob.webkitSlice;
              blob = slice.call(blob, 0, blob.size, force_saveable_type);
              blob_changed = true;
            }
            // Since I can't be sure that the guessed media type will trigger a download
            // in WebKit, I append .download to the filename.
            // https://bugs.webkit.org/show_bug.cgi?id=65440
            if (webkit_req_fs && name !== 'download') {
              name += '.download';
            }
            if (type === force_saveable_type || webkit_req_fs) {
              target_view = view;
            }
            if (!req_fs) {
              fs_error();
              return;
            }
            fs_min_size += blob.size;
            req_fs(view.TEMPORARY, fs_min_size, abortable(function (fs) {
              fs.root.getDirectory('saved', create_if_not_found, abortable(function (dir) {
                var save = function () {
                  dir.getFile(name, create_if_not_found, abortable(function (file) {
                    file.createWriter(abortable(function (writer) {
                      writer.onwriteend = function (event) {
                        target_view.location.href = file.toURL();
                        deletion_queue.push(file);
                        filesaver.readyState = filesaver.DONE;
                        dispatch(filesaver, 'writeend', event);
                      };
                      writer.onerror = function () {
                        var error = writer.error;
                        if (error.code !== error.ABORT_ERR) {
                          fs_error();
                        }
                      };
                      'writestart progress write abort'.split(' ').forEach(function (event) {
                        writer['on' + event] = filesaver['on' + event];
                      });
                      writer.write(blob);
                      filesaver.abort = function () {
                        writer.abort();
                        filesaver.readyState = filesaver.DONE;
                      };
                      filesaver.readyState = filesaver.WRITING;
                    }), fs_error);
                  }), fs_error);
                };
                dir.getFile(name, { create: false }, abortable(function (file) {
                  // delete file if it already exists
                  file.remove();
                  save();
                }), abortable(function (ex) {
                  if (ex.code === ex.NOT_FOUND_ERR) {
                    save();
                  } else {
                    fs_error();
                  }
                }));
              }), fs_error);
            }), fs_error);
          }, FS_proto = FileSaver.prototype, saveAs = function (blob, name) {
            return new FileSaver(blob, name);
          };
        ;
        FS_proto.abort = function () {
          var filesaver = this;
          filesaver.readyState = filesaver.DONE;
          dispatch(filesaver, 'abort');
        };
        FS_proto.readyState = FS_proto.INIT = 0;
        FS_proto.WRITING = 1;
        FS_proto.DONE = 2;
        FS_proto.error = FS_proto.onwritestart = FS_proto.onprogress = FS_proto.onwrite = FS_proto.onabort = FS_proto.onerror = FS_proto.onwriteend = null;
        view.addEventListener('unload', process_deletion_queue, false);
        return saveAs;
      }(typeof self !== 'undefined' && self || typeof window !== 'undefined' && window || this.content);
    return {
      save: function (content, fileName, contentType) {
        saveAs(new Blob([content], { type: contentType || 'text/html' }), fileName);
      }
    };
  }]);
'use strict';
angular.module('cpqFactoryApp').directive('preventDefault', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var eventName = attrs.preventDefault || 'click';
      element.on(eventName, function (event) {
        event.preventDefault();
      });
    }
  };
});
'use strict';
angular.module('cpqFactoryApp').directive('stopPropagation', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var eventName = attrs.stopPropagation || 'click';
      element.on(eventName, function (event) {
        event.stopPropagation();
      });
    }
  };
});
'use strict';
angular.module('cpqFactoryApp').directive('file', [function () {
    return {
      restrict: 'E',
      replace: false,
      link: function (scope, element, attributes) {
        var bind = attributes.bind;
        var input = angular.element('<input>');
        var button = angular.element('<button>');
        angular.forEach(attributes, function (value, key) {
          if (typeof value === 'string') {
            element.removeAttr(key);
            button.attr(key, value);
          }
        });
        button.html(element.html());
        element.html('');
        // create hidden file input
        input.attr('type', 'file');
        input.css('display', 'none');
        input.bind('change', function (e) {
          var file = e.target.files[0];
          scope.$apply(function () {
            button.text(file.name);
            scope.$parent[bind] = file;
          });
        });
        element.append(input);
        // pass click to file input
        button.bind('click', function () {
          input.click();
        });
        element.append(button);
      }
    };
  }]);
'use strict';
angular.module('cpqFactoryApp').directive('ngEnter', function () {
  return function (scope, element, attrs) {
    element.bind('keydown keypress', function (event) {
      if (event.which === 13) {
        scope.$apply(function () {
          scope.$eval(attrs.ngEnter);
        });
        event.preventDefault();
      }
    });
  };
});
angular.module('cpqFactoryApp').filter('quoteItemOptions', function () {
  return function (input) {
    var filtered = [];
    angular.forEach(input, function (option) {
      if ('id' in option) {
        filtered.push(option);
      }
    });
    return filtered;
  };
});
angular.module('cpqFactoryApp').filter('truncate', function () {
  return function (text, length, end) {
    if (!text) {
      return;
    }
    if (isNaN(length)) {
      length = 10;
    }
    if (end === undefined) {
      end = '...';
    }
    if (text.length <= length || text.length - end.length <= length) {
      return text;
    } else {
      return String(text).substring(0, length - end.length) + end;
    }
  };
});
angular.module('cpqFactoryApp').filter('orderObjectBy', function () {
  return function (items, field, reverse) {
    var filtered = [];
    angular.forEach(items, function (item) {
      filtered.push(item);
    });
    filtered.sort(function (a, b) {
      return a[field] > b[field];
    });
    if (reverse)
      filtered.reverse();
    return filtered;
  };
});
angular.module('cpqFactoryApp').filter('getById', function () {
  return function (input, id) {
    if (input instanceof Array) {
      var i = 0, len = input.length;
      for (; i < len; i++) {
        if (+input[i].id == +id) {
          return input[i];
        }
      }
    }
    return null;
  };
});
angular.module('cpqFactoryApp').filter('getByName', function () {
  return function (input, name) {
    if (input instanceof Array) {
      var i = 0, len = input.length;
      for (; i < len; i++) {
        if (+input[i].name == +name) {
          return input[i];
        }
      }
    }
    return null;
  };
});
'use strict';
angular.module('cpqFactoryApp').controller('MenuCtrl', [
  '$scope',
  '$location',
  '$uibModal',
  'Quote',
  'Quotes',
  'User',
  function ($scope, $location, $uibModal, Quote, Quotes, User) {
    var DEFAULT_BUTTONS = [
        {
          id: 'menu-button-categories',
          text: 'Products',
          path: '/products',
          visible: true,
          active: false,
          badge: null,
          children: []
        },
        {
          id: 'menu-button-quote',
          text: 'Quote',
          path: '/quote',
          visible: false,
          active: false,
          badge: null,
          children: []
        }
      ];
    $scope.buttons = angular.copy(DEFAULT_BUTTONS);
    $scope.$on('$routeChangeSuccess', function () {
      $scope.updateButtons();
    });
    $scope.$watch('quote', function () {
      $scope.updateButtons();
    });
    $scope.$on('QUOTE_CHANGE', function () {
      $scope.updateButtons();
    });
    // update selected buttons elements
    $scope.updateButtons = function () {
      var path = $location.path();
      Quotes.loadCurrent().then(function (quote) {
        angular.forEach($scope.buttons, function (button) {
          if (button.id === 'menu-button-quote') {
            var quoteProductCnt = quote ? quote.countProducts() : 0;
            //button.visible = !!quoteProductCnt;
            button.visible = true;
            button.badge = quoteProductCnt;
          }
          if (!button.children.length) {
            button.active = path.indexOf(button.path) === 0;
            return;
          }
          var buttonIsActive = false;
          angular.forEach(button.children, function (button) {
            button.active = path.indexOf(button.path) === 0;
            if (button.active) {
              buttonIsActive = true;
            }
          });
          button.active = buttonIsActive;
        });
      });
    };
    $scope.openImportModal = function () {
      $uibModal.open({
        templateUrl: 'views/modals/import.html',
        controller: 'ModalsImportCtrl'
      });
    };
    $scope.openCatalogsModal = function () {
      $uibModal.open({
        templateUrl: 'views/modals/catalogs.html',
        controller: 'ModalsCatalogsCtrl'
      });
    };
    $scope.openPasswordModal = function () {
      $uibModal.open({
        templateUrl: 'views/modals/password.html',
        controller: 'ModalsPasswordEntryCtrl'
      });
    };
    $scope.openOfflineDataModal = function () {
      $uibModal.open({
        templateUrl: 'views/modals/offline-data.html',
        controller: 'ModalsOfflineDataCtrl'
      });
    };
    $scope.logout = function () {
      $uibModal.open({
        templateUrl: 'views/modals/logout.html',
        controller: 'ModalsLogoutCtrl'
      });
    };
    $scope.userHasADMINRole = function () {
      return User.hasRole('ADMIN') || false;
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsCatalogsCtrl', [
  '$scope',
  '$uibModalInstance',
  '$location',
  '$route',
  'Catalogs',
  'User',
  'Quotes',
  'Status',
  function ($scope, $uibModalInstance, $location, $route, Catalogs, User, Quotes, Status) {
    $scope.title = 'Select one of your catalogs';
    Quotes.loadCurrent().then(function (quote) {
      $scope.quote = quote;
    });
    Catalogs.load().then(function (catalogs) {
      var enabled = [];
      angular.forEach(catalogs, function (catalog) {
        if (catalog.enabled) {
          enabled.push(catalog);
        }
      });
      $scope.catalogs = enabled;
      Catalogs.getUsedCatalog().then(function (used) {
        angular.forEach(enabled, function (catalog) {
          if (catalog.id === used.id) {
            $scope.catalog = catalog;
          }
        });
      });
    });
    Catalogs.getUsedCurrency().then(function (currency) {
      $scope.currency = currency;
    });
    $scope.selectCatalog = function (catalogId) {
      User.setUsedCatalogId(catalogId);
      Catalogs.getUsedCurrency().then(function (currency) {
        $scope.currency = currency;
      });
      $location.path('/products');
      $route.reload();
    };
    $scope.selectCurrency = function (currency) {
      $scope.quote = null;
      User.resetCurrentQuote();
      User.setUsedCurrency(currency);
      $scope.currency = currency;
      $location.path('/products');
      $route.reload();
    };
    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsOfflineDataCtrl', [
  '$scope',
  '$uibModalInstance',
  '$q',
  'Maintenance',
  'User',
  'Catalogs',
  function ($scope, $uibModalInstance, $q, Maintenance, User, Catalogs) {
    $scope.title = 'Offline data';
    $scope.progress = false;
    Catalogs.load().then(function (catalogs) {
      $scope.catalogs = catalogs;
    });
    $scope.updateAllData = function () {
      $scope.progress = true;
      Maintenance.updateData().then(function () {
        Catalogs.load().then(function (catalogs) {
          $scope.catalogs = catalogs;
          $scope.progress = false;
        });
      });
    };
    $scope.deleteAllData = function () {
      $q.all([
        Maintenance.deleteData(),
        Maintenance.deleteStorage()
      ]).then(function () {
        User.destroy();
        location.reload();
      });
    };
    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsDisclaimerCtrl', [
  '$scope',
  '$uibModalInstance',
  'title',
  'content',
  function ($scope, $uibModalInstance, title, content) {
    $scope.title = title;
    $scope.content = content;
    $scope.close = function () {
      $uibModalInstance.close('close');
    };
    $scope.continue = function () {
      $uibModalInstance.close('continue');
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsLogoutCtrl', [
  '$scope',
  '$uibModalInstance',
  '$location',
  '$q',
  'Api',
  'User',
  'Maintenance',
  'Status',
  'Quote',
  function ($scope, $uibModalInstance, $location, $q, Api, User, Maintenance, Status, Quote) {
    $scope.title = 'Are you sure you want to logout?';
    $scope.fullLogout = false;
    var onLogout = function () {
      if ($scope.fullLogout) {
        $q.all([
          Maintenance.deleteData(),
          Maintenance.deleteStorage()
        ]).then(function () {
          User.destroy();
        }).then($scope.restart);
      } else {
        User.lock();
        $scope.restart();
      }
    };
    $scope.logout = function () {
      if (Status.online) {
        Api.auth.logout().success(onLogout);  /*            } else {
                onLogout.call(window);*/
      }
    };
    $scope.close = function () {
      $uibModalInstance.dismiss('close');
    };
    ;
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsConfirmCtrl', [
  '$scope',
  '$uibModalInstance',
  'title',
  'message',
  function ($scope, $uibModalInstance, title, message) {
    $scope.title = title;
    $scope.message = message;
    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
    $scope.confirm = function () {
      $uibModalInstance.close('confirm');
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsAlertCtrl', [
  '$scope',
  '$uibModalInstance',
  'title',
  'message',
  function ($scope, $uibModalInstance, title, message) {
    $scope.title = title;
    $scope.message = message;
    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsImportCtrl', [
  '$scope',
  '$uibModalInstance',
  '$location',
  '$route',
  'User',
  'Quote',
  'Quotes',
  'md5',
  function ($scope, $uibModalInstance, $location, $route, User, Quote, Quotes, md5) {
    $scope.title = 'Open Quote';
    $scope.message = 'Select quote';
    $scope.quote = Quote.active;
    var validateImport = function (data) {
      var hash = data.hash;
      var quote = data.quote;
      if (hash !== md5.createHash(JSON.stringify(quote))) {
        return false;
      }
      return true;
    };
    var handleImport = function (e) {
      try {
        var data = angular.fromJson(e.target.result);
      } catch (ex) {
        $scope.error = 'Invalid file format.';
        return;
      }
      if (!validateImport(data)) {
        $scope.error = 'Quote validation failed.';
        return;
      }
      var quote = Quote.construct(data.quote, data.sapId, data.sapMessages, data.sapLastSync, data.licenseRequestedOn, data.crmLastSync);
      //remove crm links to avoid unnecessary api calls
      quote.removeCrmData();
      User.setUsedCatalogId(quote.catalog.id);
      User.setUsedCurrency(quote.currency);
      Quote.active = quote;
      //User.saveQuote(quote);
      User.setCurrentQuote(quote.guid, quote.quoteVersion);
      Quotes.save(quote).then(function () {
        $route.reload();
        if (quote.countItems() === 0) {
          $location.path('/products');
        } else {
          $location.path('/quote');
        }
        $uibModalInstance.close('submit');
      });
    };
    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
    $scope.submit = function () {
      var reader = new FileReader();
      reader.addEventListener('loadend', function (e) {
        $scope.$apply(function () {
          handleImport(e);
        });
      }, true);
      reader.readAsText($scope.file);
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsPromptCtrl', [
  '$scope',
  '$uibModalInstance',
  'title',
  'message',
  'input',
  function ($scope, $uibModalInstance, title, message, input) {
    $scope.title = title;
    $scope.input = input;
    $scope.message = message;
    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
    $scope.submit = function () {
      $uibModalInstance.close($scope.input);
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsCancelCopyNewCtrl', [
  '$scope',
  '$uibModalInstance',
  'title',
  'message',
  function ($scope, $uibModalInstance, title, message) {
    $scope.title = title;
    $scope.message = message;
    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };
    $scope.copy = function () {
      $uibModalInstance.close('copy');
    };
    $scope.new = function () {
      $uibModalInstance.close('new');
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsPasswordEntryCtrl', [
  '$scope',
  '$uibModalInstance',
  'Api',
  function ($scope, $uibModalInstance, Api) {
    $scope.errors = [];
    $scope.title = 'Change password';
    $scope.message = 'New password is applicable next time you login online.';
    $scope.cancel = function () {
      $uibModalInstance.dismiss();
    };
    $scope.confirm = function (password) {
      $scope.errors = [];
      if (password) {
        Api.users.changePassword(password);
        $uibModalInstance.close(password);
      } else {
        $scope.errors.push('No password entered.');
      }
      ;
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsAlertiwe', [
  '$scope',
  '$uibModalInstance',
  'title',
  'alerts',
  function ($scope, $uibModalInstance, title, alerts) {
    $scope.title = title;
    $scope.alerts = alerts;
    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ModalsLinkOppCtrl', [
  '$scope',
  '$uibModalInstance',
  '$location',
  '$route',
  'Status',
  function ($scope, $uibModalInstance, $location, $route, Status) {
    $scope.title = 'Link Opportunity';
    $scope.message = '';
    $scope.online = false;
    if (!Status.online) {
      $scope.message = 'It is not possible to link to an opportunity when you are offline.';
    } else {
      $scope.online = true;
      $scope.message = 'Go into the Salesforce opportunity. You can find the opportunity identifier in the url. \n E.g. : https://<your org>.my.salesforce.com/00658000007gPF5 => the identifier is 00658000007gPF5';
    }
    $scope.close = function () {
      $uibModalInstance.dismiss();
    };
    $scope.confirm = function () {
      $uibModalInstance.close($scope.opportunityId);
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('UnsupportedCtrl', [
  '$scope',
  function ($scope) {
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('LoginCtrl', [
  '$scope',
  '$location',
  '$uibModal',
  '$sce',
  'Api',
  'User',
  'Data',
  'Maintenance',
  'Catalogs',
  'Status',
  'Quote',
  'Quotes',
  'Storage',
  function ($scope, $location, $uibModal, $sce, Api, User, Data, Maintenance, Catalogs, Status, Quote, Quotes, Storage) {
    $scope.credentials = {
      username: '',
      password: ''
    };
    $scope.remember = true;
    $scope.progress = false;
    $scope.errors = [];
    if (User.isLoggedIn()) {
      $location.path('/products');
      return;
    }
    /**
         * Handles successfull login.
         */
    var onLoginSuccess = function (user) {
      var credentials = $scope.credentials;
      user.remember = $scope.remember;
      User.set(credentials, user);
      if (!User.hasRole('OPERATOR')) {
        $scope.errors.push('Access denied');
        $scope.progress = false;
        User.destroy();
        return;
      }
      Data.upgrade().then(function () {
        beginPostLoginProcedure(user);  /**
                var modal = $scope.openDisclaimerModal();
                return modal.result.then(function (result) {
                    switch (result) {
                        case 'continue':
                            return beginPostLoginProcedure(user);
                        default:
                            $scope.progress = false;
                            return;
                    }
                }, function(error){
                    $scope.progress = false;
                    User.destroy();
                    switch (error) {
                        case 'backdrop click':
                            $scope.errors.push('Access denied; you must accept the disclaimer first!');
                            break;
                        default:
                            $scope.errors.push('Access denied! Please contact support and copy following text: login.error[' + error + ']');
                            break;
                    }
                    return;
                });
                **/
      });
    };
    var beginPostLoginProcedure = function (user) {
      $scope.progress = 'Updating storage - Do not refresh -';
      return Maintenance.updateData().then(Catalogs.count).then(function (catalogCount) {
        var catalogId = User.getUsedCatalogId();
        if (!catalogId && catalogCount > 1) {
          $scope.openCatalogsModal();
        }
        var options = {};
        options.object = false;
        var returnUrl = Storage.get('returnUrl', options);
        if (returnUrl) {
          $location.path(returnUrl);
          Storage.remove('returnUrl');
        } else {
          $location.path('/products');
        }
      });
    };
    /**
         * Handles failed login.
         */
    var onLoginError = function (response) {
      if (angular.isObject(response) && 'errors' in response) {
        angular.forEach(response.errors, function (error) {
          $scope.errors.push(error.message);
        });
      } else {
        $scope.errors.push('Invalid server response.');
      }
      $scope.progress = false;
    };
    $scope.login = function () {
      $scope.errors = [];
      if (Status.online) {
        $scope.onlineLogin();  /*            } else {
                $scope.offlineLogin();*/
      }
    };
    /**
         * Performs online login.
         */
    $scope.onlineLogin = function () {
      var credentials = $scope.credentials;
      $scope.progress = 'Signing in...';
      Api.auth.login(credentials).success(onLoginSuccess).error(onLoginError);
    };
    /**
         * Performs offline login.
         */
    /*        $scope.offlineLogin = function () {
            var credentials = $scope.credentials;
            var unlocked = User.unlock(credentials);

            if (unlocked === false) {
                $scope.errors.push('Invalid credentials, please try again.');
            } else if (unlocked === null) {
                $scope.errors.push('It\'s mandatory to be online for the first sign in.');
            } else {
                $location.path('/products');
            }
        };*/
    /**
         * Opens disclaimer modal.
         */
    $scope.openDisclaimerModal = function () {
      return $uibModal.open({
        templateUrl: 'views/modals/disclaimer.html',
        controller: 'ModalsDisclaimerCtrl',
        resolve: {
          title: function () {
            return 'Disclaimer';
          },
          content: function () {
            return $sce.trustAsHtml('<p>Lorem Ipsum.</p>');
          }
        }
      });
    };
    $scope.openCatalogsModal = function () {
      return $uibModal.open({
        templateUrl: 'views/modals/catalogs.html',
        controller: 'ModalsCatalogsCtrl'
      });
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ProductsCtrl', [
  '$scope',
  'Catalogs',
  function ($scope, Catalogs) {
    Catalogs.getUsedCatalog().then(function (catalog) {
      $scope.catalog = catalog;
    });
    $scope.getCategoryImageUrl = function (category) {
      return '/static/categories/' + category.image;
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ProductsCategoryCtrl', [
  '$scope',
  '$routeParams',
  '$location',
  '$uibModal',
  '$sce',
  '$filter',
  'User',
  'Catalogs',
  'Categories',
  'Products',
  'Quote',
  'Quotes',
  'Plmstates',
  function ($scope, $routeParams, $location, $uibModal, $sce, $filter, User, Catalogs, Categories, Products, Quote, Quotes, Plmstates) {
    var categoryId = parseInt($routeParams.categoryId, 10);
    $scope.dateformat = User.getDateFormat();
    Categories.getCategoryById(categoryId).then(function (category) {
      $scope.category = category;
    });
    Products.getByCategoryId(categoryId).then(function (products) {
      $scope.products = products;
    });
    Plmstates.load().then(function (states) {
      $scope.plmstates = states;
    });
    $scope.addToTmpQuote = function (productId) {
      Quotes.loadCurrent().then(function (active) {
        // create temporary quote
        Quote.create().then(function (quote) {
          if ((!active || active.countItems() === 0) && quote.catalog.warningTextEnabled) {
            $scope.openDisclaimerModal(quote.catalog.warningText);
          }
          // add product and go to configuration
          quote.addProduct(productId, 1).then(function (itemId) {
            $location.path('/products/' + categoryId + '/configure/' + itemId);
          });
          //transfer some required datafields to temporary quote
          if (active) {
            quote.opportunityId = active.opportunityId;
            quote.opportunity = active.opportunity;
          }
          Quote.temporary = quote;
        });
      });
    };
    $scope.getProductImageUrl = function (product) {
      return '/static/products/' + product.image;
    };
    $scope.getPlmStateDescription = function (plmStatusId) {
      var state = $filter('getById')($scope.plmstates, plmStatusId);
      if (state != null) {
        return state.description;
      }
      ;
      return '';
    };
    $scope.isFirstShipdateInFuture = function (product) {
      var dateobj = new Date(product.frstshpDate);
      var today = new Date();
      var future = false;
      if (dateobj - today > 0) {
        future = true;
      }
      return future;
    };
    $scope.openDisclaimerModal = function (content) {
      return $uibModal.open({
        templateUrl: 'views/modals/disclaimer.html',
        controller: 'ModalsDisclaimerCtrl',
        resolve: {
          title: function () {
            return 'Disclaimer';
          },
          content: function () {
            return $sce.trustAsHtml(content);
          }
        }
      });
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ProductsConfigureCtrl', [
  '$scope',
  '$routeParams',
  '$location',
  '$uibModal',
  '$sce',
  '$filter',
  'Quote',
  'Quotes',
  'User',
  'Api',
  'Status',
  'Products',
  function ($scope, $routeParams, $location, $uibModal, $sce, $filter, Quote, Quotes, User, Api, Status, Products) {
    var categoryId = parseInt($routeParams.categoryId, 10);
    var itemId = parseInt($routeParams.itemId, 10);
    var serialNumbers = $routeParams.serials ? decodeURIComponent($routeParams.serials) : null;
    var symbols = {
        EUR: '\u20ac',
        USD: '$'
      };
    $scope.errorMessage = [];
    var confirm = function (title, message) {
      return $uibModal.open({
        templateUrl: 'views/modals/confirm.html',
        controller: 'ModalsConfirmCtrl',
        resolve: {
          title: function () {
            return title;
          },
          message: function () {
            return message;
          }
        }
      });
    };
    if (!Quote.temporary) {
      $location.path('/products/' + categoryId);
      return;
    }
    $scope.quote = Quote.temporary;
    if ($scope.quote.opportunityId) {
      var item = $scope.quote.items[itemId];
      if (!item.product.productCode) {
        $scope.warningMessage.push('No product code found for this item. Until this is resolved it will not be possible to sync the quote to Salesforce. Contact the responsible to complete the item and then retry !');
      }
      ;
    }
    $scope.currency = symbols[$scope.quote.getCurrency()];
    $scope.currencies = symbols;
    $scope.itemId = itemId;
    $scope.categoryId = categoryId;
    $scope.isConfigured = false;
    $scope.productsMatchingConfig = [];
    $scope.serialNumbers = serialNumbers;
    $scope.$watch('serialNumbers', function () {
      $scope.isConfigured = false;
    });
    $scope.resetProduct = function () {
      // add product and go to configuration
      var itemId = $scope.itemId;
      var quantity = $scope.quote.items[itemId].quantity;
      var productId = $scope.quote.items[itemId].productId;
      $scope.quote.removeItem(itemId);
      $scope.itemId = null;
      $scope.quote.addProduct(productId, quantity).then(function (itemId) {
        $location.path('/products/' + categoryId + '/configure/' + itemId);
      });
    };
    $scope.validate = function () {
      var item = $scope.quote.items[itemId];
      if (item) {
        return item.validateItem();
      }
      return false;
    };
    $scope.showOptions = function (quote, item, options) {
      var res = [];
      angular.forEach(options, function (opt) {
        if (opt.onlyUpgrade) {
          //initial configuration of quote item, we don't know yet
          //if it is an upgrade, but if there is a serial number given we can assume
          //it will be
          if (item.serialNumbers) {
            res.push(opt);
          }
        } else {
          res.push(opt);
        }
      });
      return res;
    };
    $scope.validateOptions = function () {
      var item = $scope.quote.items[itemId];
      if (item) {
        return item.validateRequiredOptions();
      }
      return false;
    };
    $scope.addToQuote = function () {
      var item = Quote.temporary.items[itemId];
      $scope.quote.validate().then(function (result) {
        if (result.unavailableCatalog) {
          var modalInstance = confirm('Unavailable catalog', 'Current quote is using different catalog. ' + 'Do you want to create new quote?');
          modalInstance.result.then(function (result) {
            User.resetCurrentQuote();
            $scope.addToQuote();
          });
          return;
        }
        if (result.unavailableCurrency) {
          var modalInstance = confirm('Unavailable currency', 'Current quote is using different currency. ' + 'Do you want to create new quote?');
          modalInstance.result.then(function (result) {
            User.resetCurrentQuote();
            $scope.addToQuote();
          });
          return;
        }
        if (result.outdatedCatalog) {
          var modalInstance = confirm('Outdated catalog', 'Current quote is using outdated catalog. ' + 'Do you want to update it?');
          modalInstance.result.then(function (result) {
            $scope.quote.update().then(function (updateLog) {
              $scope.addToQuote();
            });
          });
          return;
        }
        Quotes.loadCurrent().then(function (quote) {
          if (!quote) {
            Quote.create('My quote').then(function (quote) {
              quote.addItem(item);
              User.setCurrentQuote(quote.guid, quote.quoteVersion);
              Quotes.clear();
              Quotes.save(quote).then(function () {
                $scope.$emit('QUOTE_CHANGE');
                $location.path('/quote');
              });
            });
          } else {
            var regularItem = !item.isUpgradeItem();
            if (regularItem && !quote.allowRegularItems()) {
              var modalInstance = confirm('Upgrade quote', 'Current quote contains upgrade items, and you are trying to add a regular item. ' + 'Do you want to create new quote?');
              modalInstance.result.then(function (result) {
                User.resetCurrentQuote();
                $scope.addToQuote();
              });
              return;
            } else if (!regularItem && !quote.allowUpgradeItems()) {
              var modalInstance = confirm('Regular quote', 'Current quote contains regular items, and you are trying to add an upgrade item. ' + 'Do you want to create new quote?');
              modalInstance.result.then(function (result) {
                User.resetCurrentQuote();
                $scope.addToQuote();
              });
              return;
            } else {
              quote.addItem(item);
              User.setCurrentQuote(quote.guid, quote.quoteVersion);
              Quotes.save(quote).then(function () {
                $scope.$emit('QUOTE_CHANGE');
                $location.path('/quote');
              });
            }
          }
        });
      });
    };
    //this generates a digest iteration with update to angular 1.4.9
    //        $scope.getCategoryHelpText = function (category) {
    //            if (!category.mandatory) {
    //                return category.helpText;
    //            }
    //            return $sce.trustAsHtml(category.helpText + '<div style="color: #ff3c00;">This field is mandatory</div>');
    //
    //        };
    $scope.userHasUpgradeRole = function () {
      return User.hasRole('UPGRADE') || false;
    };
    $scope.isOnline = function () {
      return Status.online;
    };
    /**
         * returns product list of products that can have all the listed codes;
         */
    $scope.getProductsMatchingConfig = function (configuration, baseProductId) {
      if (!configuration) {
        return null;
      }
      return Products.load().then(function (products) {
        var matchConfig = [];
        if (!products) {
          return null;
        }
        for (var i in products) {
          var product = products[i];
          if (baseProductId) {
            //if this product is neither the specified product, nor a variant of it, skip it
            if (product.id != baseProductId && product.variantOfId != baseProductId) {
              continue;
            }
          }
          if (product.optionGroups) {
            var targetConfig = angular.copy(configuration);
            for (var j in product.optionGroups) {
              var optionGroup = product.optionGroups[j];
              if (optionGroup.optionCategories) {
                for (var k in optionGroup.optionCategories) {
                  var optionCategory = optionGroup.optionCategories[k];
                  if (optionCategory.options) {
                    for (var l in optionCategory.options) {
                      var optionIndex = targetConfig.indexOf(optionCategory.options[l].salesCode);
                      if (optionIndex > -1) {
                        targetConfig.splice(optionIndex, 1);
                        break;
                      }
                    }
                  }
                }
                //no need to check these further if we found the whole config
                if (targetConfig.length == 0) {
                  break;
                }
              }
            }
            //no need to check these further if we found the whole config
            if (targetConfig.length == 0) {
              matchConfig.push(product);
              break;
            }
          }
        }
        if (matchConfig.length == 0) {
          return null;
        }
        return matchConfig;
      });
    };
    $scope.switchProduct = function (productId) {
      // add product and go to configuration
      $scope.quote.removeItem(this.itemId);
      $scope.quote.addProduct(productId, 1).then(function (itemId) {
        $location.path('/products/' + categoryId + '/configure/' + itemId + '/serialNumbers/' + encodeURIComponent($scope.serialNumbers));
      });
    };
    $scope.getConfiguration = function (serialNumbers) {
      var item = Quote.temporary.items[itemId];
      $scope.fetching = true;
      Api.configurator.fetchConfiguration({
        serialNumbers: serialNumbers,
        productId: item.productId
      }).success(function (data) {
        $scope.fetching = false;
        $scope.isConfigured = true;
        item.cancelUpgrade();
        item.serialNumbers = serialNumbers;
        item.originalPrice = item.getUnitPrice();
        item.originalOptions = null;
        item.originalOptionsMissingCodes = null;
        item.upgradeCostCalculated = false;
        angular.forEach(data, function (d) {
          item.selectOriginalOption(d.salesCode);
        });
        if (item.originalOptionsMissingCodes) {
          //if this product is a variant, check its mother, otherwise check variants of this product
          var baseProductId = item.product.variantOfId ? item.product.variantOfId : item.product.id;
          $scope.getProductsMatchingConfig(item.originalOptions, baseProductId).then(function (result) {
            $scope.productsMatchingConfig = result;
          });
        }
      }).error(function (response) {
        $scope.fetching = false;
        $uibModal.open({
          templateUrl: 'views/modals/alert.html',
          controller: 'ModalsAlertCtrl',
          resolve: {
            title: function () {
              return 'Error';
            },
            message: function () {
              return response.errors[0].message;
            }
          }
        });
      });
    };
    if (serialNumbers && serialNumbers.match(/^\d+([,-]\d+)*$/)) {
      $scope.getConfiguration(serialNumbers);
    } else {
      $scope.serialNumbers = null;
    }
    $scope.htmlSafeCategoryHelpText = {};
    var setTooltipTexts = function (item) {
      //set tooltip/popover html texts.
      angular.forEach(item.product.optionGroups, function (group) {
        angular.forEach(group.optionCategories, function (category) {
          if (!category.mandatory) {
            $scope.htmlSafeCategoryHelpText[category.name] = $sce.trustAsHtml(category.helpText);
          } else {
            $scope.htmlSafeCategoryHelpText[category.name] = $sce.trustAsHtml(category.helpText + '<div style="color: #ff3c00;">This field is mandatory</div>');
          }
        });
      });
    };
    //set tooltip/popover html texts. 
    if ($scope.quote) {
      setTooltipTexts($scope.quote.items[$scope.itemId]);
    }
    $scope.getPriceSign = function (price) {
      if (typeof price != 'undefined') {
        if (price.diff < 0) {
          return '-';
        } else if (price.diff > 0) {
          return '+';
        } else {
          return '';
        }
        ;
      }
    };
    $scope.upgradePriceInfo = {};
    $scope.htmlSafeUpgradePriceInfo = {};
    var setUpgradePriceInfo = function (item) {
      angular.forEach($scope.quote.items[$scope.itemId].product.optionGroups, function (group) {
        angular.forEach(group.optionCategories, function (category) {
          var tmpVarOptionPrice = item.getOptionUpgradePrice(category);
          if (tmpVarOptionPrice) {
            var filter = $filter('currency');
            $scope.upgradePriceInfo[category.name] = tmpVarOptionPrice;
            $scope.htmlSafeUpgradePriceInfo[category.name] = $sce.trustAsHtml('<div style="text-decoration: line-through;">' + filter(tmpVarOptionPrice.oldPrice, $scope.currencies[tmpVarOptionPrice.currency]) + '</div><div>' + filter(tmpVarOptionPrice.newPrice, $scope.currencies[tmpVarOptionPrice.currency]) + '</div>');
          }
        });
      });
    };
    $scope.calculatePricing = function (serialNumbers) {
      var item = Quote.temporary.items[itemId];
      $scope.calculating = true;
      Api.configurator.calculatePricing({
        serialNumbers: serialNumbers,
        productId: item.productId,
        salesCodes: item.getSelectedSaleCodes()
      }).success(function (response) {
        $scope.calculating = false;
        item.setUpgrade(serialNumbers, response);
        setUpgradePriceInfo(item);
      }).error(function (response) {
        $scope.calculating = false;
        $uibModal.open({
          templateUrl: 'views/modals/alert.html',
          controller: 'ModalsAlertCtrl',
          resolve: {
            title: function () {
              return 'Error';
            },
            message: function () {
              return response.errors[0].message;
            }
          }
        });
      });
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('QuoteCtrl', [
  '$scope',
  '$rootScope',
  '$location',
  '$uibModal',
  '$window',
  '$http',
  '$compile',
  '$templateCache',
  '$filter',
  '$routeParams',
  '$interval',
  'User',
  'Quote',
  'Quotes',
  'md5',
  'FileSaver',
  '_',
  'Status',
  'Api',
  'QuoteItem',
  '$timeout',
  function ($scope, $rootScope, $location, $uibModal, $window, $http, $compile, $templateCache, $filter, $routeParams, $interval, User, Quote, Quotes, md5, FileSaver, _, Status, Api, QuoteItem, $timeout) {
    var symbols = {
        EUR: '\u20ac',
        USD: '$'
      };
    var symbolsHtml = {
        EUR: '&euro;',
        USD: '&#36;'
      };
    var oppId = $routeParams.oppId ? decodeURIComponent($routeParams.oppId) : null;
    var callGuid = $routeParams.guid ? decodeURIComponent($routeParams.guid) : null;
    var callVersion = $routeParams.version ? decodeURIComponent($routeParams.version) : null;
    $scope.successMessage = [];
    $scope.warningMessage = [];
    $scope.errorMessage = [];
    $scope.updateAvailable = false;
    $scope.validated = false;
    $scope.renaming = false;
    $scope.quoteVersions = [];
    $scope.isChanged = false;
    var loadQuoteVersions = function () {
      Quotes.loadQuoteVersions().then(function (quotes) {
        if (quotes != undefined) {
          quotes.forEach(function (quote, index, arr) {
            arr[index] = Quote.construct(quote);
          });
          $scope.quoteVersions = quotes;
        }
      });
    };
    var setnewquote = function (newQuote) {
      //Quotes.clear();
      Quotes.save(newQuote).then(function () {
        User.setCurrentQuote(newQuote.guid, newQuote.quoteVersion);
        $scope.$emit('QUOTE_CHANGE');
        $scope.quote = newQuote;
        $scope.currency = symbols[newQuote.getCurrency()];
        $location.path('/quote');
      });
    };
    var setquoteversion = function (guid, version) {
      $rootScope.lockScreen('Fetching quote, please wait...');
      Api.quoteVersions(guid).then(function (result) {
        angular.forEach(result.data, function (quoteData) {
          if (quoteData.guid == guid && quoteData.version == version) {
            setnewquote(quoteData.quote);
          } else {
            Quotes.save(quoteData.quote);
          }
          ;
          $scope.quoteVersions.push(quoteData.quote);
        });
        $rootScope.unlockScreen();
      });
    };
    var setnewopportunityquote = function (opportunity) {
      Quote.create('new opportunity quote').then(function (newQuote) {
        Quotes.clear();
        newQuote.setOpportunityData(oppId, opportunity).then(function (nqte) {
          setnewquote(nqte);
        });
      });
    };
    //using a direct link to quote / version
    if (callGuid && callVersion) {
      //check if it is in indexeddb
      Quotes.loadQuoteVersion(callGuid, callVersion).then(function (oppQuote) {
        if (!oppQuote) {
          setquoteversion(callGuid, callVersion);
        } else {
          $scope.quote = oppQuote;
          User.setCurrentQuote(oppQuote.guid, oppQuote.quoteVersion);
          $scope.currency = symbols[oppQuote.getCurrency()];
          //$scope.createNewVersion();   
          loadQuoteVersions();
        }
        ;
      });
    } else {
      //using an opportunity to create a quote
      if (oppId) {
        $rootScope.lockScreen('Fetching opportunity data, please wait...');
        Api.getOpportunity(oppId).then(function (opportunity) {
          $rootScope.unlockScreen();
          $scope.opportunity = opportunity.data;
          console.log(opportunity.data);
          //maybe this should be changed to load from opportunityId
          $rootScope.lockScreen('Fetching quote for opportunity, please wait...');
          Quotes.loadQuoteVersion($scope.opportunity.qtGuid, $scope.opportunity.qtVersion).then(function (oppQuote) {
            if (!oppQuote) {
              //it is possible that a quote already resides on the backend server, need to retrieve that one first
              Api.quoteLastOpportunity(oppId).then(function (oppQuote) {
                $scope.quote = oppQuote.data.quote;
                Quotes.save($scope.quote, true).then(function () {
                  User.setCurrentQuote($scope.quote.guid, $scope.quote.quoteVersion);
                  $scope.currency = symbols[$scope.quote.getCurrency()];
                  $scope.createNewVersion();
                  loadQuoteVersions();
                });
              }, function (error) {
                //quote doesn't exist yet, create a new one
                setnewopportunityquote(opportunity.data);
              });
            } else {
              $scope.quote = oppQuote;
              User.setCurrentQuote(oppQuote.guid, oppQuote.quoteVersion);
              $scope.currency = symbols[oppQuote.getCurrency()];
              $scope.createNewVersion();
            }
            $rootScope.unlockScreen();
          });
        });
      } else {
        Quotes.loadCurrent().then(function (quote) {
          if (!quote) {
            Quote.create('My quote').then(function (newQuote) {
              setnewquote(newQuote);
            });
          } else {
            $scope.quote = quote;
            $scope.currency = symbols[quote.getCurrency()];
          }
        });
      }
      ;
      loadQuoteVersions();
    }
    ;
    $scope.changed = function () {
      $scope.isChanged = true;
    };
    $scope.$watch('renaming', function (renaming) {
      if (!renaming && !!$scope.quote) {
        Quotes.save($scope.quote, false).then(function () {
          $scope.quoteVersions.forEach(function (qv) {
            qv.name = $scope.quote.name;
            Quotes.save(qv, false);
          });
          $rootScope.$broadcast('QUOTE_CHANGE');
        });
      }
    });
    $scope.isOnline = function () {
      return Status.online;
    };
    // variables for hiding/showing appropriate buttons during request
    // execution
    $scope.requestingLicense = false;
    $scope.syncingQuoteSap = false;
    $scope.syncingQuoteCrm = false;
    $scope.userHasRequestLicenseRole = function () {
      return User.hasRole('LICENSE_FILE') || false;
    };
    $scope.userHasSALESFORCERole = function () {
      return User.hasRole('SALESFORCE') || false;
    };
    $scope.openImportModal = function () {
      $uibModal.open({
        templateUrl: 'views/modals/import.html',
        controller: 'ModalsImportCtrl'
      });
    };
    var pollDbCrmSync = function () {
      //poll server every 10sec to see if a crm quote is created or if there are error message
      var intervalsec = 10;
      var countpol = 0;
      var crmPoll = function () {
        if ($scope.quote.syncedCrm == true) {
          countpol += 1;
          $scope.warningMessage = [];
          $scope.successMessage = [];
          $scope.warningMessage.push('Informational (the quote is synced with a background job) : Polling every ' + intervalsec + ' seconds to check if the quote is synced to crm (~1min). Number of polls: ' + countpol);
          Api.quote($scope.quote.guid, $scope.quote.quoteVersion).then(function (data) {
            var quote = data.data;
            if (quote.crmnumber || quote.syncedCrmError) {
              $scope.quote.crmnumber = quote.crmnumber;
              $scope.quote.syncedCrmError = quote.syncedCrmError;
              $scope.quote.crmSyncMessages = quote.crmSyncMessages;
              if (quote.syncedCrmError) {
                $scope.errorMessage.push($scope.quote.crmSyncMessages);
              }
              ;
              if (quote.crmnumber) {
                $scope.successMessage = [];
                $scope.warningMessage = [];
                $scope.successMessage.push('Quote is synced to the crm system, reference number:' + quote.crmnumber);
                if (quote.crmSyncMessages) {
                  $scope.successMessage.push(quote.crmSyncMessages);
                }
              }
              $scope.isChanged = true;
              Quotes.save($scope.quote, true).then(function () {
                $rootScope.$broadcast('QUOTE_CHANGE');
                $scope.isChanged = false;
              });
              $interval.cancel(interval);
            }
            ;  //sync has run save the quote
          });
        } else {
          $scope.warningMessage = [];
          $scope.successMessage = [];
          $scope.warningMessage.push('Stopped syncing. This is the ' + countpol + ' time.');
          //quote is not synced anymore, return true to stop polling
          $interval.cancel(interval);
        }
      };
      $scope.warningMessage = [];
      $scope.warningMessage.push('Polling every ' + intervalsec + ' seconds to check if the quote is synced to crm. You will be notified as soon as the quote is synced which on average takes 1 minute. Please note that if you change the quote, the system will stop syncing.');
      var interval = $interval(crmPoll, intervalsec * 1000);
    };
    $scope.syncCrm = function () {
      var valid = true;
      angular.forEach($scope.quote.items, function (item) {
        console.log(item);
        if (!item.product.productCode && item.type != 'TEXT') {
          valid = false;
          $scope.warningMessage.push('Item #' + item.id + ': No product code was assigned. Contact the administrator to complete the product data and then retry syncing !');
        }
      });
      if (valid) {
        $scope.syncingQuoteCrm = true;
        //first make sure last version of quote is on server
        Quotes.save($scope.quote, false).then(function () {
          Api.syncQuoteCrm($scope.quote).success(function (response) {
            $scope.syncingQuoteCrm = false;
            $scope.quote.crmLastSync = response.crmLastSync;
            $scope.quote.crmnumber = response.crmnumber;
            Quotes.save($scope.quote, true).then(function () {
              $scope.isChanged = false;
            });
          }).error(function (response) {
            $scope.syncingQuoteCrm = false;
          });
        });
      }
      ;
    };
    /**
        $scope.syncQuoteSap = function () {
            $scope.syncingQuoteSap = true;
            //first make sure last version of quote is on server
            Quotes.save($scope.quote, false).then(function () {
            Api.syncQuoteSap($scope.quote).success(function (response) {
                $scope.syncingQuoteSap = false;
                $scope.quote.sapLastSync = response.sapLastSync;
                $scope.quote.sapId = response.sapId;
                Quotes.save($scope.quote, true).then(function () {
                    //$rootScope.$broadcast('QUOTE_CHANGE');
                    $scope.isChanged = false;
                });
            }).error(function (response) {
                $scope.syncingQuoteSap = false;
            });
            });
        };
**/
    $scope.$watch('quote', function (quote) {
      if (!quote)
        return;
      quote.validate().then(function (result) {
        if (result.unavailableCatalog) {
          $scope.warningMessage.push('This quote is using catalog that is unavailable to you.');
          $scope.warningMessage.push('You will not be able to add new products to it.');
        } else if (result.unavailableCurrency) {
          $scope.warningMessage.push('This quote is using currency that is unavailable to you.');
          $scope.warningMessage.push('You will not be able to add new products to it.');
        } else if (result.outdatedCatalog) {
          $scope.warningMessage.push('This quote is using outdated catalog.');
          $scope.warningMessage.push('You will not be able to add new products until you update it.');
          $scope.updateAvailable = true;
        } else {
          $scope.validated = true;
        }
      });
      if (quote.syncedCrmError) {
        $scope.errorMessage.push(quote.crmSyncMessages);
      }
    });
    $scope.formatCurrency = function (value) {
      return $filter('currency')(value, symbolsHtml[$scope.quote.getCurrency()]);
    };
    $scope.addTextItem = function () {
      $scope.quote.addText();
      $scope.isChanged = true;
      Quotes.save($scope.quote, false).then(function () {
        $rootScope.$broadcast('QUOTE_CHANGE');
        $scope.isChanged = false;
      });
    };
    $scope.hideDiscounts = function (hide) {
      $scope.quote.hideDiscount(hide);
      Quotes.save($scope.quote).then(function () {
        $rootScope.$broadcast('QUOTE_CHANGE');
        $location.path('/quote');
      });
    };
    $scope.addItemCopy = function (item) {
      // do not allow copying of upgrade items
      if (item.isUpgradeItem()) {
        return;
      }
      var copyTextdesc = item.textdesc;
      var itemCopy = new QuoteItem(null, item.name, item.description, item.product, item.quantity, item.type, item.discount, copyTextdesc, item.textprice, item.serialNumbers, item.upgrade, item.originalOptions, item.originalPrice, item.unitPrice, item.tmpLicenseDate, item.tmpLicenseNo);
      $scope.quote.addItem(itemCopy);
      Quotes.save($scope.quote).then(function () {
        $rootScope.$broadcast('QUOTE_CHANGE');
        $location.path('/quote');
      });
    };
    $scope.removeItem = function (itemId) {
      var modalInstance = $uibModal.open({
          templateUrl: 'views/modals/confirm.html',
          controller: 'ModalsConfirmCtrl',
          resolve: {
            title: function () {
              return 'Please confirm';
            },
            message: function () {
              var itemName = $scope.quote.items[itemId].name;
              return 'Are you sure you want to remove ' + itemName + ' from this quote?';
            }
          }
        });
      modalInstance.result.then(function (selectedItem) {
        $scope.quote.removeItem(itemId);
        Quotes.save($scope.quote, false);
        User.setCurrentQuote($scope.quote.guid, $scope.quote.quoteVersion);
        $rootScope.$broadcast('QUOTE_CHANGE', 'DELETED_ITEM');
      });
    };
    $scope.destroy = function () {
      var modalInstance = $uibModal.open({
          templateUrl: 'views/modals/confirm.html',
          controller: 'ModalsConfirmCtrl',
          resolve: {
            title: function () {
              return 'Please confirm';
            },
            message: function () {
              return 'Are you sure you want to create a new quote?';
            }
          }
        });
      modalInstance.result.then(function (selectedItem) {
        User.resetCurrentQuote();
        $location.path('/products');
      });
    };
    $scope.createNew = function () {
      var modalInstance = $uibModal.open({
          templateUrl: 'views/modals/cancelcopynew.html',
          controller: 'ModalsCancelCopyNewCtrl',
          resolve: {
            title: function () {
              return 'Please confirm';
            },
            message: function () {
              return 'Press copy if you want to use the current quotation as a template. Note that Salesforce related data will not be copied !!!';
            }
          }
        });
      modalInstance.result.then(function (selectedItem) {
        if (selectedItem === 'copy') {
          $scope.quote.guid = $scope.quote.generateGuid();
          $scope.quote.name = $scope.quote.name + ' (copy)';
          $scope.quote.removeCrmData();
          //delete opportunity data if there is.
          User.setCurrentQuote($scope.quote.guid, $scope.quote.quoteVersion);
          Quotes.save($scope.quote).then(function () {
            $scope.$emit('QUOTE_CHANGE');
            $location.path('/quote');
          });
        } else if (selectedItem === 'new') {
          User.resetCurrentQuote();
          $location.path('/products');
        }
      });
    };
    $scope.createNewVersion = function () {
      $scope.warningMessage = [];
      $scope.successMessage = [];
      var modalInstance = $uibModal.open({
          templateUrl: 'views/modals/cancelcopynew.html',
          controller: 'ModalsCancelCopyNewCtrl',
          resolve: {
            title: function () {
              return 'Please confirm';
            },
            message: function () {
              return 'Press copy if you want to use the current quotation as a template.';
            }
          }
        });
      modalInstance.result.then(function (selectedItem) {
        $scope.quote.quoteVersion = $scope.quoteVersions[$scope.quoteVersions.length - 1].quoteVersion + 1;
        $scope.quote.syncedCrm = false;
        $scope.quote.crmnumber = null;
        $scope.quote.sapId = null;
        if (selectedItem === 'copy') {
          User.setCurrentQuote($scope.quote.guid, $scope.quote.quoteVersion);
          Quotes.save($scope.quote).then(function () {
            $scope.$emit('QUOTE_CHANGE');
            Quotes.loadQuoteVersions().then(function (quotes) {
              quotes.forEach(function (quote, index, arr) {
                arr[index] = Quote.construct(quote);
              });
              $scope.quoteVersions = quotes;
            });
          });
        } else if (selectedItem === 'new') {
          User.setCurrentQuote($scope.quote.guid, $scope.quote.quoteVersion);
          $scope.quote.clear();
          Quotes.save($scope.quote).then(function () {
            $scope.$emit('QUOTE_CHANGE');
            Quotes.loadQuoteVersions().then(function (quotes) {
              quotes.forEach(function (quote, index, arr) {
                arr[index] = Quote.construct(quote);
              });
              $scope.quoteVersions = quotes;
            });
          });
          $location.path('/products');
        }
      });
    };
    $scope.switchVersion = function (newVersion) {
      User.setCurrentQuote($scope.quote.guid, newVersion.quoteVersion);
      $scope.quote = newVersion;  // $location.path('/quote');
    };
    $scope.removeItems = function () {
      var modalInstance = $uibModal.open({
          templateUrl: 'views/modals/confirm.html',
          controller: 'ModalsConfirmCtrl',
          resolve: {
            title: function () {
              return 'Please confirm';
            },
            message: function () {
              return 'Are you sure you want to remove all items from this quote?';
            }
          }
        });
      modalInstance.result.then(function (selectedItem) {
        angular.forEach($scope.quote.items, function (item) {
          $scope.quote.removeItem(item.id);
        });
        Quotes.save($scope.quote, false).then(function () {
          $rootScope.$broadcast('QUOTE_CHANGE');
          $location.path('/quote');
        });
      });
    };
    $scope.update = function () {
      $scope.quote.update().then(function (updateLog) {
        $scope.successMessage = [];
        $scope.warningMessage = [];
        $scope.errorMessage = [];
        // display items that have been updated
        angular.forEach(updateLog.updatedItems, function (item) {
          if (item.missingOptions.length) {
            $scope.successMessage.push(item.name + ' no longer has options ' + item.missingOptions.join(', '));
          }
          $scope.successMessage.push(item.name + ' was updated succesfully');
        });
        // display items that have been removed from quote
        angular.forEach(updateLog.removedItems, function (item) {
          $scope.successMessage.push(item.name + ' was removed from the quote');
        });
        $scope.updateAvailable = false;
        $scope.validated = true;
        Quotes.save($scope.quote, false);
        $rootScope.$broadcast('QUOTE_CHANGE');
      });
    };
    $scope.save = function (isChanged) {
      if (isChanged) {
        if ($scope.quote.syncedCrm) {
          $scope.warningMessage.push('This quote version is NOT synced anymore with Salesforce. Press "Salesforce Synced" to re-confirm syncing.');
          $scope.quote.syncedCrm = false;
        }
        Quotes.save($scope.quote, false).then(function () {
          $rootScope.$broadcast('QUOTE_CHANGE');
          $scope.isChanged = false;
        });
      }
    };
    $scope.validate = function () {
      // TODO
      return true;
    };
    $scope.preview = function () {
      $scope.quote.generate();
      Quotes.save($scope.quote).then(function () {
        $window.open('#/quote/preview');
      });
    };
    $scope.exportJson = function () {
      var date = new Date();
      var json = JSON.stringify({
          exported: new Date(),
          hash: md5.createHash(JSON.stringify($scope.quote)),
          quote: $scope.quote
        });
      var fileName = $scope.quote.getFileName() + '.quote';
      FileSaver.save(json, fileName, 'application/json');
    };
    $scope.exportWord = function () {
      $scope.quote.generate();
      $http.get('quote.doc', { cache: $templateCache }).then(function (response) {
        var html = _.template(response.data, $scope);
        // .replace(/<!--(.*?)-->/g, '');
        var fileName = $scope.quote.getFileName() + '.doc';
        FileSaver.save(html, fileName, 'application/msword');
      });
    };
    $scope.itemsOrderBy = function (item) {
      if (item) {
        return parseInt(item.id, 10);
      } else {
        return null;
      }
    };
    $scope.refreshOpportunity = function (oppId) {
      var modalInstance = $uibModal.open({
          templateUrl: 'views/modals/confirm.html',
          controller: 'ModalsConfirmCtrl',
          resolve: {
            title: function () {
              return 'Please confirm refresh';
            },
            message: function () {
              return 'Be aware that all dimensions on the line items will be overwritten with the new values from the opportunities. Are you sure ?';
            }
          }
        });
      modalInstance.result.then(function (selectedItem) {
        if (selectedItem === 'confirm') {
          $rootScope.lockScreen('Fetching opportunity data, please wait...');
          Api.getOpportunity(oppId).then(function (opportunity) {
            $scope.quote.setOpportunityData(oppId, opportunity.data).then(function (qte) {
              $scope.quote = qte;
              $scope.isChanged = true;
              $scope.save($scope.isChanged);
            });
            $rootScope.unlockScreen();
          });
        } else {
          $location.path('/quote');
        }
      });
    };
    $scope.linkOpportunity = function () {
      var modalInstance = $uibModal.open({
          templateUrl: 'views/modals/linkopportunity.html',
          controller: 'ModalsLinkOppCtrl',
          resolve: {}
        });
      modalInstance.result.then(function (oppId) {
        if (oppId) {
          $rootScope.lockScreen('Fetching opportunity data, please wait...');
          Api.getOpportunity(oppId).then(function (opportunity) {
            $scope.quote.setOpportunityData(oppId, opportunity.data).then(function (qte) {
              $scope.quote = qte;
              $scope.isChanged = true;
              $scope.save($scope.isChanged);
            });
            $rootScope.unlockScreen();
          });
        }
      });
    };
    $scope.moveItem = function (index, type, quote) {
      quote.moveItem(index, type);
      $scope.isChanged = true;
      $scope.save($scope.isChanged);
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('QuoteConfigureCtrl', [
  '$scope',
  '$rootScope',
  '$routeParams',
  '$location',
  '$uibModal',
  '$filter',
  '$sce',
  'Quote',
  'Quotes',
  'User',
  'Api',
  'Status',
  function ($scope, $rootScope, $routeParams, $location, $uibModal, $filter, $sce, Quote, Quotes, User, Api, Status) {
    var itemId = parseInt($routeParams.itemId, 10);
    var symbols = {
        EUR: '\u20ac',
        USD: '$'
      };
    $scope.htmlSafeCategoryHelpText = {};
    $scope.htmlSafePriceInfoHtml = {};
    var setTooltipTexts = function (item) {
      //set tooltip/popover html texts.
      angular.forEach(item.product.optionGroups, function (group) {
        angular.forEach(group.optionCategories, function (category) {
          if (!category.mandatory) {
            $scope.htmlSafeCategoryHelpText[category.name] = $sce.trustAsHtml(category.helpText);
          } else {
            $scope.htmlSafeCategoryHelpText[category.name] = $sce.trustAsHtml(category.helpText + '<div style="color: #ff3c00;">This field is mandatory</div>');
          }
        });
      });
    };
    Quotes.loadCurrent().then(function (quote) {
      if (!quote)
        return;
      $scope.quote = quote;
      $scope.currency = symbols[quote.getCurrency()];
      /*<quote : upgrading existing items>*/
      var item = $scope.quote.items[itemId];
      if (typeof item != 'undefined') {
        $scope.serialNumbers = item.serialNumbers;
        if (typeof item.upgrade != 'undefined') {
          $scope.isConfigured = true;
        }
      }
      setTooltipTexts(item);  /*</quote : upgrading existing items>*/
    });
    $scope.itemId = itemId;
    $scope.currencies = symbols;
    /*<quote : upgrading existing items>*/
    //all between these two marks has equivalent in products/configure.js
    $scope.isConfigured = false;
    //variables for hiding/showing appropriate buttons during request execution
    $scope.calculating = false;
    $scope.upgradePriceInfo = {};
    $scope.htmlSafeUpgradePriceInfo = {};
    var setUpgradePriceInfo = function (item) {
      angular.forEach($scope.quote.items[$scope.itemId].product.optionGroups, function (group) {
        angular.forEach(group.optionCategories, function (category) {
          var tmpVarOptionPrice = item.getOptionUpgradePrice(category);
          if (tmpVarOptionPrice) {
            var filter = $filter('currency');
            $scope.upgradePriceInfo[category.name] = tmpVarOptionPrice;
            $scope.htmlSafeUpgradePriceInfo[category.name] = $sce.trustAsHtml('<div style="text-decoration: line-through;">' + filter(tmpVarOptionPrice.oldPrice, $scope.currencies[tmpVarOptionPrice.currency]) + '</div><div>' + filter(tmpVarOptionPrice.newPrice, $scope.currencies[tmpVarOptionPrice.currency]) + '</div>');
          }
        });
      });
    };
    $scope.calculatePricing = function (serialNumbers) {
      if (!$scope.quote)
        return false;
      var item = $scope.quote.items[$scope.itemId];
      $scope.calculating = true;
      Api.configurator.calculatePricing({
        serialNumbers: serialNumbers,
        productId: item.product.id,
        salesCodes: item.getSelectedSaleCodes()
      }).success(function (response) {
        $scope.calculating = false;
        item.setUpgrade(serialNumbers, response);
        setUpgradePriceInfo(item);
      }).error(function (response) {
        $scope.calculating = false;
        $uibModal.open({
          templateUrl: 'views/modals/alert.html',
          controller: 'ModalsAlertCtrl',
          resolve: {
            title: function () {
              return 'Error';
            },
            message: function () {
              return response.errors[0].message;
            }
          }
        });
      });
    };
    $scope.userHasUpgradeRole = function () {
      return User.hasRole('UPGRADE') || false;
    };
    $scope.canUpgradeItem = function () {
      //if user does not have upgrade role, don't show upgrade functionality
      if (!this.userHasUpgradeRole()) {
        return false;
      }
      //if the quote is of type ZUQT or the quote doesn't have any items yet, then we can upgrade
      //otherwise, item cannot be upgraded
      if ($scope.quote && ($scope.quote.type === 'ZUQT' || $scope.quote.countItems() > 1)) {
        return true;
      }
      return false;
    };
    $scope.isOnline = function () {
      return Status.online;
    };
    /*</quote : upgrading existing items>*/
    $scope.validate = function () {
      if (!$scope.quote)
        return false;
      var item = $scope.quote.items[itemId];
      return item.validateItem();
    };
    $scope.showOptions = function (quote, item, options) {
      var res = [];
      angular.forEach(options, function (opt) {
        if (opt.onlyUpgrade) {
          if (quote.type == 'ZUQT') {
            res.push(opt);
          }
        } else {
          res.push(opt);
        }
      });
      return res;
    };
    $scope.validateOptions = function () {
      if (!$scope.quote)
        return false;
      var item = $scope.quote.items[itemId];
      return item.validateRequiredOptions();
    };
    $scope.saveAndContinue = function () {
      $scope.save();
      $location.path('/quote');
    };
    $scope.saveAndEdit = function () {
      $scope.save();
      $location.path('/quote/configure/' + itemId);
    };
    $scope.save = function () {
      Quotes.save($scope.quote, false).then(function () {
        $rootScope.$broadcast('QUOTE_CHANGE');  //$scope.$emit('QUOTE_CHANGE');
      });
    };
    $scope.saved = function () {
      return angular.equals($scope.quote, Quote.active);
    };
    //        $scope.getPriceInfoHtml = function (info) {
    //            if (!info) {
    //                return null;
    //            }
    //            var filter = $filter('currency');
    //            return $sce.trustAsHtml('<div style="text-decoration: line-through;">' +
    //                filter(info.oldPrice, $scope.currencies[info.currency]) + '</div><div>' +
    //                filter(info.newPrice, $scope.currencies[info.currency]) + '</div>');
    //        };
    $scope.getPriceSign = function (price) {
      if (typeof price != 'undefined') {
        if (price.diff < 0) {
          return '-';
        } else if (price.diff > 0) {
          return '+';
        } else {
          return '';
        }
        ;
      }
    };
    $scope.tmpLicense = function (selectedOption) {
      $scope.showTmpLicenseNo = false;
      $scope.showTmpLicenseDate = false;
      if (selectedOption.salesCode == 'TP-01') {
        $scope.showTmpLicenseDate = true;
      }
      if (selectedOption.salesCode == 'TP-02') {
        $scope.showTmpLicenseNo = true;
      }
      ;
    };
  }
]);
'use strict';
angular.module('cpqFactoryApp').controller('ArchivedCtrl', [
  '$rootScope',
  '$scope',
  '$location',
  '$uibModal',
  'Quotes',
  'User',
  '$q',
  'Maintenance',
  'Status',
  'Quote',
  'Api',
  function ($rootScope, $scope, $location, $uibModal, Quotes, User, $q, Maintenance, Status, Quote, Api) {
    $scope.limit = 20;
    $scope.activeQuoteGuid = User.getCurrentQuoteGuid();
    $scope.activeQuoteVersion = User.getCurrentQuoteVersion();
    $scope.fetchinQuotes = false;
    $scope.forceSyncSingleQuote = function (quote) {
      if (Status.online) {
        return Maintenance.syncSingleQuote(quote).then(Quotes.loadQuotes).then(function (quotes) {
          quotes.forEach(function (quote, index, arr) {
            arr[index] = Quote.construct(quote);
          });
          $scope.quotes = quotes;
        });
      } else {
        return Quotes.loadQuotes().then(function (quotes) {
          quotes.forEach(function (quote, index, arr) {
            arr[index] = Quote.construct(quote);
          });
          $scope.quotes = quotes;
        });
      }
    };
    $scope.forceSyncAllQuotes = function () {
      $scope.fetchinQuotes = true;
      Api.quotes.query({
        limit: $scope.limit,
        order: 'DESC'
      }, function (result) {
        //(save all funciton doesn't work
        angular.forEach(result.items, function (qte) {
          var quote = qte.quote;
          quote.synced = Date.now();
          //save without setting updated => else it will be synced back
          //to the server
          Quotes.save(quote, true);
        });
        Quotes.loadQuotes().then(function (quotes) {
          quotes.forEach(function (quote, index, arr) {
            arr[index] = Quote.construct(quote);
          });
          $scope.quotes = quotes;
        });
        $scope.fetchinQuotes = false;
      });
    };
    //$scope.forceSyncAllQuotes($scope.quotes);
    Quotes.loadQuotes().then(function (quotes) {
      quotes.forEach(function (quote, index, arr) {
        arr[index] = Quote.construct(quote);
      });
      $scope.quotes = quotes;
    });
    $scope.loadQuote = function (quote) {
      User.setCurrentQuote(quote.guid, quote.quoteVersion);
      $location.path('/quote');
    };
    $scope.deleteQuote = function (quote) {
      var modalInstance = $uibModal.open({
          templateUrl: 'views/modals/confirm.html',
          controller: 'ModalsConfirmCtrl',
          resolve: {
            title: function () {
              return 'Please confirm';
            },
            message: function () {
              return 'Are you sure you whant to delete quote ' + quote.name + '?';
            }
          }
        });
      modalInstance.result.then(function (selectedItem) {
        quote.deleted = true;
        Maintenance.syncSingleQuote(quote).then(function () {
          if (User.getCurrentQuoteGuid() === quote.guid) {
            User.resetCurrentQuote();
          }
          Quotes.loadQuotes().then(function (quotes) {
            if (quotes.length > 0) {
              if (!User.getCurrentQuoteGuid()) {
                User.setCurrentQuote(quotes.reverse()[0].guid, quotes.reverse()[0].quoteVersion);
                $scope.activeQuoteGuid = User.getCurrentQuoteGuid();
              }
              quotes.forEach(function (quote, index, arr) {
                arr[index] = Quote.construct(quote);
              });
              $scope.quotes = quotes;
            } else {
              $location.path('/quote');
            }
          });
        });
      });
    };
  }
]);